import {
  BorderRadiusStyles,
  ButtonConfigurations,
  ButtonSizeOptions,
  ButtonTypes,
  GlobalThemeConfiguration,
  InputFieldsConfigurations,
  InputLabelOptions,
  NewColourPaletteType,
  WidgetNames,
} from '../config.type';
import { NewWorkflowWidgetConfig } from '../workflow.v2.type';
import { BorderRadiusClasses, TypographyKeys } from './global-styling.enum';

export const defaultColourPalette: NewColourPaletteType = {
  main: {
    primary: '#3574FC',
    background: '#F6F6F6',
    surface: '#FFFFFF',
    borderColor: '#CED4DA',
  },
  typography: {
    onPrimary: '#FFFFFF',
    onPrimaryMuted: '#F5F5F5',
    onBackground: '#000B0C',
    onBackgroundMuted: '#757575',
    onSurface: '#000B0C',
    onSurfaceMuted: '#757575',
  },
  states: {
    active: '#9747FF',
    hover: '#023197',
  },
  status: {
    error: '#EF0606',
    success: '#1BB12F',
    info: '#FFF7CF',
    warning: '#FFC008',
  },
  customColors: [],
};

export const defaultLoaderCss = `
  .loader-container {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(+25px, -25px);
  }

  .loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: radial-gradient(farthest-side, #5e6566 94%, #0000) top/8px 8px no-repeat,
      conic-gradient(#0000 30%, #5e6566);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
    animation: l13 1s infinite linear;
  }

  @keyframes l13 {
    100% {
      transform: rotate(1turn);
    }
  }
`;

export const defaultGlobalThemeGeneralConfig: Pick<GlobalThemeConfiguration, 'generalConfigurations'> = {
  generalConfigurations: {
    companyInfo: {
      name: 'Finxone',
      legalEntityName: 'Finxone Ltd',
      logo: '',
      logoTitle: '',
      logoFileName: '',
    },
    defaultSystemFont: false,
    fontFamily: 'Mulish',
    secondaryFontFamily: 'Mulish',
    palette: defaultColourPalette,
    loaderCss: defaultLoaderCss,
  },
};

export const defaultGlobalThemeTypographyConfig: Pick<GlobalThemeConfiguration, 'typography'> = {
  typography: {
    [TypographyKeys.HEADING1]: {
      'font-family': 'var(--font-family)',
      'font-weight': '700',
      'font-size': '72px',
      'text-decoration': 'none',
      'letter-spacing': '0px',
      'line-height': '1.25',
      'margin-bottom': '0px',
    },
    [TypographyKeys.HEADING2]: {
      'font-family': 'var(--font-family)',
      'font-weight': '700',
      'font-size': '60px',
      'text-decoration': 'none',
      'letter-spacing': '0px',
      'line-height': '1.2',
      'margin-bottom': '0px',
    },
    [TypographyKeys.HEADING3]: {
      'font-family': 'var(--font-family)',
      'font-weight': '700',
      'font-size': '48px',
      'text-decoration': 'none',
      'letter-spacing': '0px',
      'line-height': '1.25',
      'margin-bottom': '0',
    },
    [TypographyKeys.HEADING4]: {
      'font-family': 'var(--font-family)',
      'font-weight': '700',
      'font-size': '36px',
      'text-decoration': 'none',
      'letter-spacing': '0px',
      'line-height': '1.22',
      'margin-bottom': '0px',
    },
    [TypographyKeys.HEADING5]: {
      'font-family': 'var(--font-family)',
      'font-weight': '700',
      'font-size': '30px',
      'text-decoration': 'none',
      'letter-spacing': '0px',
      'line-height': '1.27',
      'margin-bottom': '0px',
    },
    [TypographyKeys.HEADING6]: {
      'font-family': 'var(--font-family)',
      'font-weight': '700',
      'font-size': '24px',
      'text-decoration': 'none',
      'letter-spacing': '0px',
      'line-height': '1.33',
      'margin-bottom': '0px',
    },
    [TypographyKeys.SUBTITLE1]: {
      'font-family': 'var(--font-family)',
      'font-weight': '400',
      'font-size': '18px',
      'text-decoration': 'none',
      'letter-spacing': '0px',
      'line-height': '1.56',
      'margin-bottom': '0px',
    },
    [TypographyKeys.SUBTITLE2]: {
      'font-family': 'var(--font-family)',
      'font-weight': '400',
      'font-size': '14px',
      'text-decoration': 'none',
      'letter-spacing': '0px',
      'line-height': '1.43',
      'margin-bottom': '0px',
    },
    [TypographyKeys.BODY1]: {
      'font-family': 'var(--font-family)',
      'font-weight': '400',
      'font-size': '16px',
      'text-decoration': 'none',
      'letter-spacing': '0px',
      'line-height': '1.5',
      'margin-bottom': '0px',
    },
    [TypographyKeys.BODY2]: {
      'font-family': 'var(--font-family)',
      'font-weight': '700',
      'font-size': '16px',
      'text-decoration': 'none',
      'letter-spacing': '0px',
      'line-height': '1.5',
      'margin-bottom': '0px',
    },
    [TypographyKeys.BUTTON_TEXT]: {
      'font-family': 'var(--font-family)',
      'font-weight': '400',
      'font-size': '16px',
      'text-decoration': 'none',
      'letter-spacing': '0px',
      'line-height': '1.5',
      'margin-bottom': '0px',
    },
    [TypographyKeys.LABEL_TEXT]: {
      'font-family': 'var(--font-family)',
      'font-weight': '400',
      'font-size': '14px',
      'text-decoration': 'none',
      'letter-spacing': '0px',
      'line-height': '1.43',
      'margin-bottom': '0px',
    },
  },
};

type ButtonStatesType = 'active' | 'idle' | 'hover';

export const primaryButtonStatesConfig: Pick<ButtonConfigurations, ButtonStatesType> = {
  idle: {
    'background-color': 'var(--color-primary)',
    'border-color': 'var(--color-primary)',
    textColor: {
      color: 'var(--color-surface)',
    },
  },
  active: {
    'background-color': 'var(--color-active)',
    'border-color': 'var(--color-active)',
    textColor: {
      color: 'var(--color-surface)',
    },
  },
  hover: {
    'background-color': 'var(--color-hover)',
    'border-color': 'var(--color-hover)',
    textColor: {
      color: 'var(--color-surface)',
    },
  },
};

export const secondaryButtonStatesConfig: Pick<ButtonConfigurations, ButtonStatesType> = {
  idle: {
    'background-color': 'var(--color-surface)',
    'border-color': 'var(--color-primary)',
    textColor: {
      color: 'var(--color-primary)',
    },
  },
  active: {
    'background-color': 'var(--color-surface)',
    'border-color': 'var(--color-active)',
    textColor: {
      color: 'var(--color-active)',
    },
  },
  hover: {
    'background-color': 'var(--color-surface)',
    'border-color': 'var(--color-hover)',
    textColor: {
      color: 'var(--color-hover)',
    },
  },
};

export const tertiaryButtonStatesConfig: Pick<ButtonConfigurations, ButtonStatesType> = {
  idle: {
    'background-color': 'var(--color-surface)',
    'border-color': 'transparent',
    textColor: {
      color: 'var(--color-active)',
    },
  },
  active: {
    'background-color': 'var(--color-surface)',
    'border-color': 'transparent',
    textColor: {
      color: 'var(--color-active)',
    },
  },
  hover: {
    'background-color': 'var(--color-surface)',
    'border-color': 'transparent',
    textColor: {
      color: 'var(--color-hover)',
    },
  },
};

export const menuButtonStatesConfig: Pick<ButtonConfigurations, ButtonStatesType> = {
  idle: {
    'background-color': 'var(--color-surface)',
    'border-color': 'var(--color-border-color)',
    textColor: {
      color: 'var(--color-on-surface)',
    },
  },
  active: {
    'background-color': 'var(--color-surface)',
    'border-color': 'var(--color-active)',
    textColor: {
      color: 'var(--color-on-surface)',
    },
  },
  hover: {
    'background-color': 'var(--color-surface)',
    'border-color': 'var(--color-hover)',
    textColor: {
      color: 'var(--color-on-surface)',
    },
  },
};

function getButtonStateConfigByButtonType(
  buttonType: ButtonTypes,
): Pick<ButtonConfigurations, 'active' | 'idle' | 'hover'> {
  switch (buttonType) {
    case ButtonTypes.PRIMARY:
      return primaryButtonStatesConfig;
    case ButtonTypes.SECONDARY:
      return secondaryButtonStatesConfig;
    case ButtonTypes.TERTIARY:
      return tertiaryButtonStatesConfig;
    case ButtonTypes.MENU:
      return menuButtonStatesConfig;
  }
}

export function generateDefaultButtonThemeConfigurations(type: ButtonTypes): Partial<ButtonConfigurations> {
  let defaultConfig: Partial<ButtonConfigurations> = getButtonStateConfigByButtonType(type);
  if (type !== ButtonTypes.TERTIARY) {
    defaultConfig = {
      ...defaultConfig,
      baseStyles: {
        'justify-content': 'center',
        'align-items': 'center',
        'border-style': 'solid',
        'box-shadow': '0px 0px 0px 0px rgba(0, 0, 0, 0.1)',
        'border-top-left-radius': '8px',
        'border-top-right-radius': '8px',
        'border-bottom-right-radius': '8px',
        'border-bottom-left-radius': '8px',
        'border-top-width': '1px',
        'border-bottom-width': '1px',
        'border-left-width': '1px',
        'border-right-width': '1px',
      },
      borderRadiusClass: BorderRadiusClasses.NONE,
    };
  }
  if (type !== ButtonTypes.SECONDARY && type !== ButtonTypes.TERTIARY) {
    defaultConfig = {
      ...defaultConfig,
      size: {
        [ButtonSizeOptions.SMALL]: {
          'padding-top': '20px',
          'padding-right': '20px',
          'padding-bottom': '20px',
          'padding-left': '20px',
          'margin-top': '0px',
          'margin-right': '0px',
          'margin-bottom': '0px',
          'margin-left': '0px',
          paddingWithIcon: {
            gap: '6px',
            'padding-right': '15px',
            'padding-left': '15px',
          },
        },
        [ButtonSizeOptions.LARGE]: {
          'padding-top': '25px',
          'padding-right': '30px',
          'padding-bottom': '25px',
          'padding-left': '30px',
          'margin-top': '0px',
          'margin-right': '0px',
          'margin-bottom': '0px',
          'margin-left': '0px',
          paddingWithIcon: {
            gap: '6px',
            'padding-right': '25px',
            'padding-left': '25px',
          },
        },
        [ButtonSizeOptions.FULL_WIDTH]: {
          'padding-top': '20px',
          'padding-right': '0px',
          'padding-bottom': '20px',
          'padding-left': '0px',
          'margin-top': '0px',
          'margin-right': '0px',
          'margin-bottom': '0px',
          'margin-left': '0px',
          paddingWithIcon: {
            gap: '6px',
            'padding-right': '0px',
            'padding-left': '0px',
          },
        },
      },
    };
  }
  if (type === ButtonTypes.MENU) {
    defaultConfig = {
      ...defaultConfig,
      iconConfigurations: {
        type: 'right',
        iconName: 'arrow-right-jamboo',
        height: '18px',
        width: '18px',
        color: 'var(--color-on-surface)',
      },
    };
  }
  return defaultConfig;
}

export const defaultGlobalThemeRadiusItemConfig: BorderRadiusStyles = {
  'border-top-left-radius': '8px',
  'border-top-right-radius': '8px',
  'border-bottom-right-radius': '8px',
  'border-bottom-left-radius': '8px',
};

export const defaultGlobalThemeRadiusTwoItemConfig: BorderRadiusStyles = {
  'border-top-left-radius': '25px',
  'border-top-right-radius': '25px',
  'border-bottom-right-radius': '25px',
  'border-bottom-left-radius': '25px',
};

export const defaultInputFieldConfigs: InputFieldsConfigurations = {
  idle: {
    'background-color': 'var(--color-surface)',
    'border-color': 'var(--color-border-color)',
    textColor: {
      color: 'var(--color-on-surface-muted)',
    },
    labelColor: {
      color: 'var(--color-on-surface-muted)',
    },
  },
  active: {
    'background-color': 'var(--color-surface)',
    'border-color': 'var(--color-active)',
    textColor: {
      color: 'var(--color-on-surface-muted)',
    },
    labelColor: {
      color: 'var(--color-on-surface-muted)',
    },
  },
  labelConfig: {
    type: InputLabelOptions.STATIC,
    'align-items': 'center',
    'justify-content': 'start',
    'padding-top': '10px',
    'padding-right': '5px',
    'padding-bottom': '10px',
    'padding-left': '5px',
  },
  inputStyles: {
    'align-items': 'center',
    'justify-content': 'start',
    'padding-top': '8px',
    'padding-right': '4px',
    'padding-bottom': '8px',
    'padding-left': '4px',
    height: '40px',
    width: '100%',
    'border-top-left-radius': '5px',
    'border-top-right-radius': '5px',
    'border-bottom-right-radius': '5px',
    'border-bottom-left-radius': '5px',
    'border-top-width': '1px',
    'border-left-width': '1px',
    'border-bottom-width': '1px',
    'border-right-width': '1px',
    'box-shadow': '0px 0px 0px 0px rgba(0, 0, 0, 0.1)',
    'border-style': 'solid',
  },
  textStyleClass: 'body1',
  borderRadiusClass: BorderRadiusClasses.RADIUS_ONE,
  otpInputFieldSpacing: {
    'margin-right': '5px',
  },
};

export const defaultGlobalThemeRadiusConfig: {
  [key in Exclude<BorderRadiusClasses, BorderRadiusClasses.NONE>]: BorderRadiusStyles;
} = {
  [BorderRadiusClasses.RADIUS_ONE]: defaultGlobalThemeRadiusItemConfig,
  [BorderRadiusClasses.RADIUS_TWO]: defaultGlobalThemeRadiusTwoItemConfig,
};

export const globalThemeConstant: GlobalThemeConfiguration = {
  themeName: 'Default',
  generalConfigurations: defaultGlobalThemeGeneralConfig.generalConfigurations,
  buttons: {
    [ButtonTypes.PRIMARY]: generateDefaultButtonThemeConfigurations(ButtonTypes.PRIMARY),
    [ButtonTypes.SECONDARY]: generateDefaultButtonThemeConfigurations(ButtonTypes.SECONDARY),
    [ButtonTypes.TERTIARY]: generateDefaultButtonThemeConfigurations(ButtonTypes.TERTIARY),
    [ButtonTypes.MENU]: generateDefaultButtonThemeConfigurations(ButtonTypes.MENU),
  },
  typography: defaultGlobalThemeTypographyConfig.typography,
  inputFields: defaultInputFieldConfigs,
  radius: defaultGlobalThemeRadiusConfig,
} as GlobalThemeConfiguration;

export const NewThemeVarsToExistingPaletteMap = new Map<string, string>([
  ['primary', 'palette-3'],
  ['background', 'palette-6'],
  ['surface', 'palette-5'],
  ['borderColor', 'palette-4'],
  ['onBackground', 'palette-0'],
  ['onBackgroundMuted', 'palette-1'],
  ['error', 'alerts-0'],
  ['success', 'alerts-1'],
  ['info', 'alerts-2'],
  ['warning', 'alerts-3'],
]);

export const NewToOldTypographyVarsMap = new Map<string, string>([
  ['onBackground', 'typography-0'],
  ['onBackgroundMuted', 'typography-1'],
  ['onSurfaceMuted', 'typography-2'],
]);

export const NewToOldBorderVarsMap = new Map<string, string>([
  ['primary', 'border-0'],
  ['border', 'border-1'],
  ['onSurfaceMuted', 'border-2'],
]);

const buttonWidgets = [
  WidgetNames.QUICK_LINKS_WIDGET,
  WidgetNames.MULTI_ICON_QUICK_LINK_WIDGET,
  WidgetNames.CTA_BUTTON_WIDGET,
  WidgetNames.BACK_BUTTON_WIDGET,
  WidgetNames.MANDATORY_CTA_BUTTON,
  WidgetNames.FORM_SUBMISSION_BUTTON,
  WidgetNames.ONBOARDING_BACK_NEXT_BUTTON,
  WidgetNames.WIDGET_NESTED_BUTTON,
];

export const onSetDefaultButtonGlobalStyling = (widget: NewWorkflowWidgetConfig) => {
  if (buttonWidgets.includes(widget.name as WidgetNames)) {
    widget = {
      ...widget,
      widgetConfiguration: {
        ...widget.widgetConfiguration,
        attributes: {
          ...widget.widgetConfiguration?.attributes,
          widgetProperties: {
            ...widget.widgetConfiguration?.attributes?.widgetProperties,
            globalStyling: true,
            globalStylingClass:
              widget.name === WidgetNames.MULTI_ICON_QUICK_LINK_WIDGET
                ? `${ButtonTypes.MENU} ${ButtonSizeOptions.FULL_WIDTH}`
                : `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`,
          },
        },
      },
    };
  }

  return widget;
};
