import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { BaseWidgetProperties, PaymentStatusScreen } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { map, take } from 'rxjs';
import { ClearFormDataActionWithId, UpdateFormDataAction } from '../../../actions/form-submission.action';
import {
  CreatePaymentRequest,
  PaymentRequestFailed,
  PaymentRequestSuccess,
} from '../../../actions/payment-request.action';
import { PaymentRequestorRequest } from '../../../services/account-service/account.type';
import { AccountState } from '../../../state/account.state';
import { FormActionState } from '../../../state/form-submision.state';

export function paymentRequestProcessing(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  action: Actions,
  router: Router,
) {
  const formState = store.selectSnapshot(FormActionState.getFormActionState);
  const activeAccountId = store.selectSnapshot(AccountState.getCurrentAccountId);

  if (formState?.response?.formData) {
    router.navigateByUrl(`/zones/payments/${PaymentStatusScreen.PAYMENT_PROCESSING}`, {
      replaceUrl: true,
    });

    const sepaFormState = store.selectSnapshot(
      FormActionState.getFormActionStateWithId(FormActionTypeEnum.SEPA_PAYMENT),
    )?.formData;

    store.dispatch(
      new CreatePaymentRequest({
        ...formState?.response?.formData,
        sourceAccountId: activeAccountId,
        paymentRailOption: sepaFormState?.paymentRail,
      } as PaymentRequestorRequest),
    );
    store.dispatch(
      new UpdateFormDataAction({
        transferDate: `${new Date()}`,
      }),
    );

    action
      .pipe(
        ofActionDispatched(PaymentRequestSuccess),
        take(1),
        map(() => {
          store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.SEPA_PAYMENT));
          store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.SEPA_PAYMENT_RAIL_FLAG));
          router.navigateByUrl(`/zones/payments/${PaymentStatusScreen.PAYMENT_REQUEST_SENT}`, {
            replaceUrl: true,
          });
        }),
      )
      .subscribe();

    action
      .pipe(
        ofActionDispatched(PaymentRequestFailed),
        take(1),
        map(() => {
          store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.SEPA_PAYMENT));
          store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.SEPA_PAYMENT_RAIL_FLAG));
          router.navigateByUrl(`/zones/payments/${PaymentStatusScreen.PAYMENT_REQUEST_FAILED}`, {
            replaceUrl: true,
          });
        }),
      )
      .subscribe();
  }
}
