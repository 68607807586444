import { LayoutWidgetConfig } from './config.type';
import {
  BaseCssConfig,
  BaseWidgetProperties,
  ComponentLabel,
  ContainerType,
  GridPosition,
  SupportedDevice,
  ValidationRules,
  WidgetFields,
} from './workflow.type';
import { ModalConfiguration, WorkflowPageStateConfig, ZoneConfiguration } from './workflow.v2.type';

export function defaultCssProperties(): BaseCssConfig {
  return {
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginUnit: 'px',
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingUnit: 'px',
    selectedOpacity: 100,
    isTextBold: false,
    isiTextItalic: false,
    isTextUnderline: false,
    shadowBlur: 0,
    shadowSpread: 0,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: '',
    alertColor: '',
  };
}

export function defaultGridPosition(): GridPosition {
  return {
    x: 0,
    y: 0,
    rows: 2,
    cols: 15,
    dragEnabled: false,
    resizeEnabled: false,
  };
}

export type UiZoneWidgetConfig = OldUiZoneWidgetConfig | NewUiZoneWidgetConfig;

export type CommonUiZoneWidgetConfig = {
  name: ComponentLabel;
  uniqueId: string | number;
  attributes: UiZoneWidgetAttributeConfig;
  nestedWidgets?: {
    [uniqueId: string]: UiZoneWidgetAttributeConfig;
  };
  startHooks?: WidgetHooks[];
  endHooks?: WidgetHooks[];
};

export type OldUiZoneWidgetConfig = CommonUiZoneWidgetConfig & {
  gridPosition: GridPosition;
};

export type NewUiZoneWidgetConfig = CommonUiZoneWidgetConfig & {
  childComponents?: NewUiZoneWidgetConfig[] | NewUiZoneWidgetConfig[][];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const is2dArray = (array: any[] | undefined) => (array ? Array.isArray(array[0]) : false);

export function isNewUiZoneWidgetConfig(config: UiZoneWidgetConfig): config is NewUiZoneWidgetConfig {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ((<UiZoneWidgetConfig>config) as any)['childComponents'] !== undefined &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ((<UiZoneWidgetConfig>config) as any)['gridPosition'] === undefined
  );
}

export enum WidgetHooks {
  EXCHANGE_RESET_ACCOUNTS = 'exchange-reset-accounts',
  RESET_BENEFICIARY_FORMS = 'reset-beneficiary-forms',
  RESET_SECONDARY_IDENTIFIER_COP = 'reset-secondary-identifier-cop',
}

export type UiZoneWidgetAttributeConfig = {
  css_config: BaseCssConfig;
  outerCss?: string;
  elementsConfig: WidgetFields[];
  hideCondition?: string;
  widgetProperties: BaseWidgetProperties;
  validationRules?: ValidationRules[];
  workflowFormConfig?: {
    // self means one form control that widget registers itself
    // page means form is registered elsewhere
    type: 'self' | 'page';
    formPath: string;
  };
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  [key: string]: any;
};
export type PageHooks = {
  name: string;
  enabled: boolean;
};

export type UiPageConfig = {
  url: string;
  mainComponentName: string;
  pageTypeConfig: 'new' | 'wrapper';
  widgetLayouts: UiZoneWidgetConfig[];
  title: string;
  /// Should remove but needed for workflow ui for now as dynamic grid component looks for it to use in
  // injector creation
  widgetSections: WorkflowPageStateConfig[];
  pageType: 'dynamicGrid' | 'staticGrid';
  containerType?: ContainerType;
  pageConfiguration?: UiPagePageConfiguration;
};

export type UiPagePageConfiguration = {
  labelsAsPlaceholder?: boolean;
  fontsConfig?: {
    [key: string]: { [key: string]: string };
    h1: { 'font-family': string; 'font-weight': string };
    h2: { 'font-family': string; 'font-weight': string };
    h3: { 'font-family': string; 'font-weight': string };
    bodyFont: { 'font-family': string; 'font-weight': string };
  };
  gridsterMargin: {
    left: number;
    right: number;
    top: number;
    bottom?: number;
    unit: string;
  };
  pageMargin?: {
    left: number;
    right: number;
    top: number;
    bottom?: number;
    unit: string;
  };
  backgroundColor?: string;
  backgroundSize?: string;
  backgroundGradient?: string;
  backgroundImage?: {
    image?: string;
    opacity: string | number;
    objectFitType?: string;
  };
  showHeader: boolean;
  borderConfig?: {
    width: string;
    unit: string;
    color: string;
    borderRadius: {
      [key: string]: string;
      topLeft: string;
      topRight: string;
      bottomLeft: string;
      bottomRight: string;
      unit: string;
    };
  };
  backgroundPosition?: {
    x: string;
    y: string;
  };
  isCustomGridActive?: boolean;
  isBodyScrollingEnabled?: boolean;
  autoRedirectConfig?: {
    isAutoRedirectEnabled?: boolean;
    autoRedirectTimer?: number;
    autoRedirectPersona?: string;
    autoRedirectDestinationZone?: string;
    autoRedirectDestinationPageIndex?: number;
    autoRedirectDestinationPageUrl?: string;
  };
  pageHooks?: PageHooks[];
  isUsingHtmlStructure?: boolean;
  modalConfiguration?: ModalConfiguration;
  hideNavigationMenu: boolean;
};

export type UiZoneConfig = {
  zoneConfiguration: ZoneConfiguration;
  pages: UiPageConfig[];
};

// Utility used to tell difference between UiZoneDevicesConfig and Default in CanvasSubscription
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function isUiZoneConfig(config: any): config is UiZoneConfig {
  return (<UiZoneConfig>config)?.pages !== undefined;
}
// Utility used to tell difference between UiZoneDevicesConfig and Default in CanvasSubscription
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function isUiPageConfig(config: any): config is UiPageConfig {
  return (<UiPageConfig>config)?.pageTypeConfig !== undefined;
}

export function isUiZoneWidgetConfig(
  config: UiZoneWidgetConfig | LayoutWidgetConfig,
): config is UiZoneWidgetConfig {
  return (<LayoutWidgetConfig>config)?.position === undefined;
}

export type UiZoneDevicesConfig = {
  [key in SupportedDevice]: UiZoneConfig;
};

export type UiZoneSubscriptionsConfig = {
  [key: string]: UiZoneDevicesConfig;
};

export type UiZoneRolesConfig = {
  [key: string]: UiZoneSubscriptionsConfig;
};
