import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ConfigService } from '@app/finxone-web-frontend/app/lib/services/config-service/config-service.service';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import { APP_ZONES, UiPageConfig } from '@finxone-platform/shared/sys-config-types';
import { Subject, takeUntil } from 'rxjs';
import { MetadataService } from '../lib/services/metadata-service/metadata-service.service';
import { clearLocalStorageExcept, isTokenExpired } from '../lib/utils/utils';
import { formatNameAsUrl } from '../lib/utils/zone-url.utils';

@Component({
  selector: 'finxone-web-frontend-feature-redirect',
  template: ` <div></div> `,
})
export class FeatureRedirectComponent {
  @AutoDestroy destroy$: Subject<void> = new Subject<void>();
  currentZoneFromRoute: Record<string, string | number>;
  constructor(
    private configService: ConfigService,
    private metadataService: MetadataService,
    private _router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params: ParamMap) => {
      this.currentZoneFromRoute = {
        zone: params.get('zone') ?? '',
        role: params.get('role') ?? '',
        subscription: params.get('subscription') ?? '',
        onboardingOrgId: params.get('onboardingOrgId') ?? '',
      };
    });
    this.RedirectUser();
  }

  RedirectUser() {
    // if still sitting on feature redirect after 4 seconds reload the page again
    setTimeout(() => {
      if (window.location.pathname.includes('feature-redirect')) {
        window.location.reload();
      }
    }, 4000);

    if ((this.currentZoneFromRoute['zone'] as string).length) {
      this.configService
        .getCurrentRoleZonePagesConfig(this.currentZoneFromRoute)
        .subscribe((response: UiPageConfig[]) => {
          const redirectionUrl = `/zones/${formatNameAsUrl(this.currentZoneFromRoute['zone'] as string)}/${
            response[0].url
          }/${this.currentZoneFromRoute['role']}/${this.currentZoneFromRoute['subscription']}/${
            this.currentZoneFromRoute?.['onboardingOrgId']
          }`;
          this._router.navigateByUrl(redirectionUrl);
        });
    } else {
      const token = localStorage.getItem('token') as string;
      if (token && isTokenExpired(token)) {
        console.log(`FeatureRedirectComponent RedirectUser: clearLocalStorageExcept`);
        clearLocalStorageExcept([
          'account',
          'transactions',
          'beneficiary',
          'notifications',
          'lastLoggedInId',
          'APP_PREFERENCES',
          'firstTime',
        ]);
        this._router.navigate(['/']);
      }
      if (token == null) {
        const firstTimeComplete = localStorage.getItem('firstTime');

        if (firstTimeComplete == null) {
          localStorage.setItem('firstTime', 'true');
          this._router.navigate(['zones', formatNameAsUrl(APP_ZONES.SIGNED_OUT_FIRST_INTRODUCTION)]);
        } else {
          this._router.navigate(['zones', formatNameAsUrl(APP_ZONES.SIGNED_OUT_INTRODUCTION)]);
        }
      } else {
        this._router.navigateByUrl('post-auth');
      }
    }
  }
}
