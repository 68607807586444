import {
  FormActionState,
  FormActionStateModel,
  FormActionType,
} from '@app/finxone-web-frontend/app/lib/state/form-submision.state';
import { createPasswordRulesTemplate } from '@app/finxone-web-frontend/app/lib/utils/password-field.utils';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { BaseComponent } from '../base/base.component';

export interface PasswordValidationRules {
  upperCase?: number;
  lowerCase?: number;
  specialChars?: number;
  digits?: number;
  length?: number;
  maxLength?: number;
}

export abstract class FormSubmitter extends BaseComponent {
  @AutoDestroy destroy$: Subject<void> = new Subject<void>();
  @Select(FormActionState.getFormActionState)
  formAction!: Observable<FormActionStateModel>;
  @Select(FormActionState.getFormActionStateWithId)
  formActionWithId!: Observable<FormActionStateModel>;
  public passwordRulesMessages: { [key: string]: string } = {};
  constructor(protected store: Store) {
    super();
  }

  subscribeToFormAction(id?: string): Observable<FormActionType | null> {
    if (id) {
      return this.store.select(FormActionState.getFormActionStateWithId(id)).pipe(
        takeUntil(this.destroy$),
        map((response) => {
          return response;
        }),
      );
    } else {
      return this.formAction.pipe(
        takeUntil(this.destroy$),
        map((response) => {
          return response?.response;
        }),
      );
    }
  }
  getFormActionDataWithId(id: string): Observable<Record<string, any> | null> {
    return this.formAction.pipe(
      takeUntil(this.destroy$),
      map((response) => {
        return response?.[id];
      }),
    );
  }

  public isShowAndErorIcon(elementsConfig: any[]): {
    isShowErrorIcon: boolean;
    isShowSuccessIcon: boolean;
  } {
    const showErorIcon = elementsConfig?.find((item) => item.field_name === 'isShowErrorIcon');
    const showSuccessIcon = elementsConfig?.find((item) => item.field_name === 'isShowSuccessIcon');
    return {
      isShowErrorIcon: showErorIcon?.show ?? false,
      isShowSuccessIcon: showSuccessIcon?.show ?? false,
    };
  }

  public parsePasswordPolicy(policyString: string): PasswordValidationRules {
    const passwordValidationRules: PasswordValidationRules = {};
    // Regular expression to match rules like length(8), upperCase(1)
    const ruleRegex = /(\w+)(\((\d+)\))?/g;
    let match;
    while ((match = ruleRegex.exec(policyString)) !== null) {
      // Rule name (e.g., length, maxLength) & Parameter if exists, otherwise undefined
      passwordValidationRules[match[1] as keyof PasswordValidationRules] = match[3]
        ? parseInt(match[3], 10)
        : undefined;
    }
    this.passwordRulesMessages = createPasswordRulesTemplate(passwordValidationRules);
    return passwordValidationRules;
  }
}
