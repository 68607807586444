import { SEPAPaymentRailTypeEnum } from '@finxone-platform/form-action';

export const paymentValidationsForSEPA: {
  [key: string]: {
    maximumTransactionLimit?: number;
    beneficiaryAddressRequired?: boolean;
    beneficiaryBICRequired?: boolean;
  };
} = {
  [SEPAPaymentRailTypeEnum.SEPA_CREDIT_TRANSFER]: {
    maximumTransactionLimit: 999999999.99,
  },
  [SEPAPaymentRailTypeEnum.SEPA_CREDIT_TRANSFER_INSTANT]: {
    maximumTransactionLimit: 100000,
  },
  [SEPAPaymentRailTypeEnum.TARGET2]: {
    beneficiaryAddressRequired: true,
    beneficiaryBICRequired: true,
  },
};
