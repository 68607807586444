import { Injectable, signal } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  ActiveDevice,
  AlertItemConfigurationType,
  AlertPositions,
  AlertStyleType,
  AlertTypes,
  BaseCssProperties,
  baseCssConfigStyle,
  hexToRgb,
} from '@finxone-platform/shared/sys-config-types';

import { BehaviorSubject } from 'rxjs';

export interface Alert {
  type: string | AlertTypes;
  msg: string | undefined;
  headerMsg: string | undefined;
  isShowCloseIcon?: boolean | undefined;
  displayAlert?: boolean;
  position?: baseCssConfigStyle;
  dismissalType?: string;
  contentStyle?: baseCssConfigStyle;
  alertTitleStyle?: { 'font-size': string; 'font-family': string; color: string };
  alertDescriptionStyle?: { 'font-size': string; 'font-family': string; color: string };
  leftIcon?: { name: string; size: string; color: string; unit: string };
  rightIcon?: { name: string; size: string; color: string; unit: string };
}

export interface AlertPositionStyles {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  'justify-content'?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AlertHandlerService {
  private isAlertConfigurationChanged = signal<boolean>(false);
  private alerts: Alert[] = [];
  public showAlert$ = new BehaviorSubject<Alert[]>(this.alerts);
  private timer = 4000;
  public showAlertFn(
    event: string | AlertTypes,
    msg?: string,
    headerMsg?: string,
    timer?: number,
    isShowCloseIcon?: boolean,
    displayAlert?: boolean,
  ) {
    const alert: Alert = {
      type: event,
      msg: msg,
      headerMsg: headerMsg,
      isShowCloseIcon: isShowCloseIcon ?? true,
      displayAlert: displayAlert ?? true,
    };
    this.alerts.push(alert);
    this.showAlert$.next(this.alerts);
  }

  public resetAlertFn() {
    this.alerts = [];
    this.showAlert$.next(this.alerts);
  }

  public hideAlert(alertIndexToRemove?: number) {
    if (alertIndexToRemove !== undefined) {
      this.alerts.splice(alertIndexToRemove, 1);
    } else {
      this.alerts = [];
    }
    this.showAlert$.next(this.alerts);
  }

  public getAlertStyleConfig(config: AlertItemConfigurationType, propertyName: Array<string>) {
    let cssProperty = {};
    propertyName.forEach((cssPropertyName) => {
      switch (cssPropertyName) {
        case BaseCssProperties.MARGIN:
          cssProperty = {
            ...cssProperty,
            ...this.getAlertStyleCssMargin(config.styles),
          };
          break;

        case BaseCssProperties.PADDING:
          cssProperty = {
            ...cssProperty,
            ...this.getAlertStyleCssPadding(config.styles),
          };
          break;

        case BaseCssProperties.MIN_WIDTH:
          cssProperty = {
            ...cssProperty,
            ...this.getAlertStyleCssMinWidth(config.styles),
          };
          break;

        case BaseCssProperties.TEXTALIGN:
          cssProperty = {
            ...cssProperty,
            ...this.getAlertStyleCssContentAlignment(config.styles),
          };
          break;

        case BaseCssProperties.BACKGROUND_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getAlertStyleCssBackGroundColor(config.styles),
          };
          break;

        case BaseCssProperties.TITLE_FONT:
          cssProperty = {
            ...cssProperty,
            ...this.getAlertStyleCssTitleFont(config.styles),
          };
          break;

        case BaseCssProperties.DESCRIPTION_FONT:
          cssProperty = {
            ...cssProperty,
            ...this.getAlertStyleCssDescriptionFont(config.styles),
          };
          break;

        case BaseCssProperties.ALERT_LEFT_ICON:
          cssProperty = {
            ...cssProperty,
            ...this.getAlertLeftIconConfig(config.styles),
          };
          break;

        case BaseCssProperties.ALERT_RIGHT_ICON:
          cssProperty = {
            ...cssProperty,
            ...this.getAlertRightIconConfig(config.styles),
          };
          break;

        case BaseCssProperties.BOX_SHADOW:
          cssProperty = {
            ...cssProperty,
            ...this.getBoxShadow(config.styles),
          };
          break;

        case BaseCssProperties.ALERT_BORDER:
          cssProperty = {
            ...cssProperty,
            ...this.getAlertBorder(config.styles),
          };
          break;

        default:
          break;
      }
    });

    return cssProperty;
  }

  private getAlertStyleCssMargin(alertConfiguration: AlertStyleType) {
    return {
      'margin-top': `${alertConfiguration?.marginTop ?? 0}${alertConfiguration?.marginUnit ?? 'px'}`,
      'margin-left': `${alertConfiguration?.marginLeft ?? 0}${alertConfiguration?.marginUnit ?? 'px'}`,
      'margin-right': `${alertConfiguration?.marginRight ?? 0}${alertConfiguration?.marginUnit ?? 'px'}`,
      'margin-bottom': `${alertConfiguration?.marginBottom ?? 0}${alertConfiguration?.marginUnit ?? 'px'}`,
    };
  }

  private getAlertStyleCssPadding(alertConfiguration: AlertStyleType) {
    return {
      'padding-top': `${alertConfiguration?.paddingTop ?? 0}${alertConfiguration?.paddingUnit ?? 'px'}`,
      'padding-left': `${alertConfiguration?.paddingLeft ?? 0}${alertConfiguration?.paddingUnit ?? 'px'}`,
      'padding-right': `${alertConfiguration?.paddingRight ?? 0}${alertConfiguration?.paddingUnit ?? 'px'}`,
      'padding-bottom': `${alertConfiguration?.paddingBottom ?? 0}${alertConfiguration?.paddingUnit ?? 'px'}`,
    };
  }

  private getAlertStyleCssMinWidth(alertConfiguration: AlertStyleType) {
    return {
      'min-width': `${alertConfiguration?.minWidth ?? 0}${alertConfiguration?.minWidthUnit ?? 'px'}`,
    };
  }

  private getAlertStyleCssContentAlignment(alertConfiguration: AlertStyleType) {
    return {
      'text-align': `${alertConfiguration?.textAlignment ?? 'left'} `,
    };
  }

  private getAlertStyleCssBackGroundColor(alertConfiguration: AlertStyleType) {
    const rgb = hexToRgb(alertConfiguration.backGroundColor ?? '', 'withoutRgbWrapper');
    return {
      'background-color': `rgba(${rgb},${Number(alertConfiguration.backgroundOpacity) / 100})`,
    };
  }

  private getAlertStyleCssTitleFont(alertConfiguration: AlertStyleType) {
    return {
      'font-size': `${alertConfiguration?.textStyle.titleFontSize ?? 16}px`,
      'font-family': `${alertConfiguration?.textStyle.titleFontFamily ?? 'var(--font-family)'}`,
      color: `${alertConfiguration?.textStyle.titleColor}`,
    };
  }

  private getAlertStyleCssDescriptionFont(alertConfiguration: AlertStyleType) {
    return {
      'font-size': `${alertConfiguration?.textStyle.descriptionFontSize ?? 16}px`,
      'font-family': `${alertConfiguration?.textStyle.descriptionFontFamily ?? 'var(--font-family)'}`,
      color: `${alertConfiguration?.textStyle.descriptionColor}`,
    };
  }

  private getAlertLeftIconConfig(alertConfiguration: AlertStyleType) {
    return {
      name: `${alertConfiguration?.iconConfiguration?.['leftIcon']?.name ?? ''}`,
      size: `${alertConfiguration?.iconConfiguration?.['leftIcon']?.size ?? 0}${
        alertConfiguration?.iconConfiguration?.['leftIcon']?.unit ?? 'px'
      }`,
      color: `${alertConfiguration?.iconConfiguration?.['leftIcon']?.color ?? ''}`,
    };
  }

  private getAlertRightIconConfig(alertConfiguration: AlertStyleType) {
    return {
      name: `${alertConfiguration?.iconConfiguration?.['rightIcon']?.name ?? ''}`,
      size: `${alertConfiguration?.iconConfiguration?.['rightIcon']?.size ?? 0}${
        alertConfiguration?.iconConfiguration?.['rightIcon']?.unit ?? 'px'
      }`,
      color: `${alertConfiguration?.iconConfiguration?.['rightIcon']?.color ?? ''}`,
    };
  }

  public getAlertPositionStyles(
    position: AlertPositions | undefined,
    activeDevice: ActiveDevice,
  ): AlertPositionStyles {
    switch (position) {
      case AlertPositions.CENTER:
        return {
          top: '50%',
          bottom: 'unset',
          'justify-content': 'center',
        };
      case AlertPositions.CENTER_LEFT: {
        const style: AlertPositionStyles = {
          top: '50%',
          bottom: 'unset',
          'justify-content': 'start',
        };
        if (activeDevice !== ActiveDevice.MOBILE) style.left = '1.5rem';
        return style;
      }
      case AlertPositions.CENTER_RIGHT:
        return {
          top: '50%',
          bottom: 'unset',
          'justify-content': 'end',
          right: '1.5rem',
        };
      case AlertPositions.LOWER_CENTER:
        return {
          bottom: '10%',
          top: 'unset',
          'justify-content': 'center',
        };
      case AlertPositions.LOWER_LEFT: {
        const style: AlertPositionStyles = {
          bottom: '10%',
          top: 'unset',
          'justify-content': 'start',
        };
        if (activeDevice !== ActiveDevice.MOBILE) style.left = '1.5rem';
        return style;
      }
      case AlertPositions.LOWER_RIGHT:
        return {
          bottom: '10%',
          top: 'unset',
          'justify-content': 'end',
          right: '1.5rem',
        };
      case AlertPositions.UPPER_CENTER:
        return {
          top: '10%',
          bottom: 'unset',
          'justify-content': 'center',
        };
      case AlertPositions.UPPER_LEFT: {
        const style: AlertPositionStyles = {
          top: '10%',
          bottom: 'unset',
          'justify-content': 'start',
        };
        if (activeDevice !== ActiveDevice.MOBILE) style.left = '1.5rem';
        return style;
      }
      case AlertPositions.UPPER_RIGHT:
        return {
          top: '10%',
          bottom: 'unset',
          'justify-content': 'end',
          right: '1.5rem',
        };
      default:
        return {};
    }
  }

  private getBoxShadow(alertConfiguration: AlertStyleType) {
    return {
      'box-shadow': `${alertConfiguration?.shadowOffsetX + 'px'} ${
        alertConfiguration?.shadowOffsetY + 'px'
      } ${alertConfiguration?.shadowBlur + 'px'} ${alertConfiguration?.shadowSpread + 'px'} ${
        alertConfiguration?.shadowColor
      }`,
    };
  }

  private getAlertBorder(alertConfiguration: AlertStyleType) {
    return {
      'border-radius': `${alertConfiguration?.borderRadiusTopLeft ?? 0}${
        alertConfiguration?.borderRadiusUnit ?? 'px'
      } ${alertConfiguration?.borderRadiusTopRight ?? 0}${alertConfiguration?.borderRadiusUnit ?? 'px'} ${
        alertConfiguration?.borderRadiusBottomRight ?? 0
      }${alertConfiguration?.borderRadiusUnit ?? 'px'} ${alertConfiguration?.borderRadiusBottomLeft ?? 0}${
        alertConfiguration?.borderRadiusUnit ?? 'px'
      }`,
      'border-width': `${alertConfiguration?.borderWidthTop ?? 0}${
        alertConfiguration?.borderWidthUnit ?? 'px'
      } ${alertConfiguration?.borderWidthRight ?? 0}${alertConfiguration?.borderWidthUnit ?? 'px'} ${
        alertConfiguration?.borderWidthBottom ?? 0
      }${alertConfiguration?.borderWidthUnit ?? 'px'} ${alertConfiguration?.borderWidthLeft ?? 0}${
        alertConfiguration?.borderWidthUnit ?? 'px'
      }`,
      'border-color': `${alertConfiguration?.borderColor ?? ''}`,
      'border-style': `solid`,
    };
  }

  public getIsAlertConfigurationChanged() {
    return this.isAlertConfigurationChanged();
  }

  public setIsAlertConfigurationChanged(value: boolean) {
    this.isAlertConfigurationChanged.set(value);
  }
}
