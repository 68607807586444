import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@app/finxone-web-frontend/app/lib/services/config-service/config-service.service';
import { TransactionType } from '@finxone-platform/shared/sys-config-types';
import { Observable, catchError } from 'rxjs';
import { TransactionDetailResponse } from '../account-service/account.type';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  private baseUrl = '';

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.configService.getApi('account_service').subscribe((response) => {
      this.baseUrl = response;
    });
  }

  public getTransactionDetailById(
    transactionNumber: string,
    transactionId: string,
    transactionType: TransactionType,
  ): Observable<TransactionDetailResponse> {
    let url: string;
    if (transactionNumber) {
      url = `${this.baseUrl}/payments/transactions?transactionNumber=${transactionNumber}&type=${transactionType}`;
    } else {
      url = `${this.baseUrl}/payments/transactions?id=${transactionId}&type=${transactionType}`;
    }
    return this.http.get<TransactionDetailResponse>(url).pipe(
      catchError<any, Observable<boolean>>((_err) => {
        throw _err;
      }),
    );
  }
}
