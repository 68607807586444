import { CardTransactionDetail } from '@finxone-platform/shared/sys-config-types';

export const currentCardTransactionDetailSkeleton: CardTransactionDetail = {
  id: '<div class="skeleton_75"></div>',
  description: '<div class="skeleton_50"></div>',
  type: '<div class="skeleton_25"></div>',
  transactionInfo: {
    totalAmount: '<div class="skeleton_50"></div>',
    amount: '<div class="skeleton_50"></div>',
    currency: '<div class="skeleton_75"></div>',
    originalAmount: '<div class="skeleton_50"></div>',
    originalAmountCurrency: '<div class="skeleton_25"></div>',
  },
  merchantInfo: {
    name: '<div class="skeleton_75"></div>',
    city: '<div class="skeleton_50"></div>',
    country: '<div class="skeleton_50"></div>',
    id: '<div class="skeleton_100"></div>',
    streetNumber: '<div class="skeleton_25"></div>',
    address: '<div class="skeleton_50"></div>',
    postalCode: '<div class="skeleton_75"></div>',
    image: '<div class="skeleton_50"></div>',
    phone: '<div class="skeleton_25"></div>',
  },
  date: '<div class="skeleton_100"></div>',
  status: '<div class="skeleton_100"></div>',
  feeInfo: {
    feeAmount: '<div class="skeleton_25"></div>',
    feeCurrency: '<div class="skeleton_50"></div>',
    ecbMarkup: '<div class="skeleton_75"></div>',
    atmFeeAmount: '<div class="skeleton_50"></div>',
    atmFeeCurrency: '<div class="skeleton_25"></div>',
    exchangeRate: '<div class="skeleton_100"></div>',
    exchangeRateCurrency: '<div class="skeleton_75"></div>',
  },
};
