<div [ngStyle]="contentStyle" class="section password-forgot pointer-events-none">
  <p *ngIf="dataIn?.attri?.widgetProperties?.subHeadingLabel" [ngStyle]="mergedHeadingStyle">
    {{ dataIn?.attri?.widgetProperties?.subHeadingLabel }}
  </p>
  <div class="text-left mt-3">
    <form class="beneficiary-form" [formGroup]="passwordForm">
      <div>
        <div class="field-wrapper position-relative">
          <finxone-input
            [type]="fieldTypes['newPassword']"
            [name]="'newPassword'"
            [label]="dataIn?.attri?.widgetProperties['passFieldLabel1']"
            formControlName="newPassword"
            [formControlInvalid]="
              !!(
                passwordForm.controls['newPassword']?.dirty &&
                passwordForm.controls['newPassword']?.invalid &&
                passwordForm.controls['newPassword']?.touched
              )
            "
            [value]="passwordForm.controls['newPassword']?.value ?? ''"
            [validationConfig]="[
              {
                type: 'required',
                message: 'Password fields cannot be empty'
              }
            ]"
          >
          <div
            suffix
            *ngTemplateOutlet="inputFieldIcons; context: {
              formGroup: passwordForm,
              control: passwordForm.get('newPassword'),
              formControlName: 'newPassword',
              iconsConfig: passwordFieldConfigs.cssConfig,
              fieldTypes: fieldTypes,
              passwordFieldValidators: passwordFieldValidators,
            }"
          ></div>
          </finxone-input>
          <div
            class="text-error typog-xs"
            *ngIf="passwordForm.get('confirmPassword')?.dirty && passwordForm.hasError('noPassswordMatch')"
          >
            <span>Passwords do not match. Please try again</span>
          </div>
        </div>
        <div class="my-3 position-relative">
          <finxone-input
            [type]="fieldTypes['confirmPassword']"
            [name]="'confirmPassword'"
            [label]="dataIn?.attri?.widgetProperties['passFieldLabel2']"
            formControlName="confirmPassword"
            [formControlInvalid]="
              !!(
                passwordForm.controls['confirmPassword']?.dirty &&
                passwordForm.controls['confirmPassword']?.invalid &&
                passwordForm.controls['confirmPassword']?.touched
              )
            "
            [value]="passwordForm.controls['confirmPassword']?.value ?? ''"
            [validationConfig]="[
              {
                type: 'required',
                message: 'Password fields cannot be empty'
              }
            ]"
          >
          <div
            suffix
            *ngTemplateOutlet="inputFieldIcons; context: {
              formGroup: passwordForm,
              control: passwordForm.get('confirmPassword'),
              formControlName: 'confirmPassword',
              iconsConfig: passwordFieldConfigs.cssConfig,
              fieldTypes: fieldTypes,
              passwordFieldValidators: passwordFieldValidators,
            }"
          ></div>
          </finxone-input>
          <div
            class="text-error typog-xs"
            *ngIf="passwordForm.get('confirmPassword')?.dirty && passwordForm.hasError('noPassswordMatch')"
          >
            <span>Passwords do not match. Please try again</span>
          </div>
        </div>
        @if (passwordFieldValidators) {
          <div class="password-rules my-3">
            @for (passwordRule of keysOfPasswordRules; track passwordRule) {
            <span
              [class.highlighted-password-rule]="validateFieldForSuccessHighlighting(passwordRule)"
              [class.highlighted-password-rule-error]="validateFieldForErrorHighlighting(passwordRule)"
              class="typog-sm body1 textOnSurfaceMuted"
              >{{ passwordRulesMessages[passwordRule]
              }}<span *ngIf="$index < keysOfPasswordRules.length - 2">, </span
              ><span *ngIf="$index === keysOfPasswordRules.length - 2">{{ ' ' + 'and' + ' ' }}</span></span
            >
            }
          </div>
        } @else {
          <p-skeleton width="100%" styleClass="mb-2" />
          <p-skeleton width="75%" styleClass="mb-2" />
        }
        <div>
          <finx-button
            [class]="isAllowedGlobalStyling ? globalStylingClass : 'w-100'"
            (click)="enableSubmit = true; onSubmit()"
            [buttonStyle]="!isAllowedGlobalStyling ? reviewDetailsButtonStyle : {}"
            [class.mandatory-cta-button]="!isAllowedGlobalStyling"
            [isAllowedGlobalStyling]="isAllowedGlobalStyling"
            type="submit"
            [labeltext]="buttonContent"
            [disabled]="!formIsValid"
          ></finx-button>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template
  #inputFieldIcons
  let-formGroup="formGroup"
  let-control="control"
  let-formControlName="formControlName"
  let-iconsConfig="iconsConfig"
  let-fieldTypes="fieldTypes"
  let-passwordFieldValidators="passwordFieldValidators"
>
  <div class="password-field-icons gap-1">
    <span
      class="password-field-eye-icon"
      (click)="togglePasswordFieldType(formControlName, 'text')"
      *ngIf="fieldTypes[formControlName] === 'password' && iconsConfig.showPasswordIconVisible"
    >
      <mat-icon [svgIcon]="iconsConfig.showPasswordIcon"></mat-icon>
    </span>
    <span
      class="password-field-eye-icon"
      (click)="togglePasswordFieldType(formControlName, 'password')"
      *ngIf="fieldTypes[formControlName] === 'text' && iconsConfig.showPasswordIconVisible"
    >
      <mat-icon [svgIcon]="iconsConfig.hidePasswordIcon"></mat-icon>
    </span>
    <span
      class="password-field-success-icon"
      *ngIf="
        control?.dirty &&
        control?.value.length >= passwordFieldValidators?.length &&
        control?.value.length <= passwordFieldValidators?.maxLength &&
        control?.valid
      "
    >
      <mat-icon [svgIcon]="iconsConfig.successIcon"></mat-icon>
    </span>
    <span
      class="password-field-error-icon"
      *ngIf="
        (control?.dirty &&
          (control?.value.length < passwordFieldValidators?.length ||
            control?.value.length > passwordFieldValidators?.maxLength)) ||
        (control?.invalid && control?.touched)
      "
    >
      <mat-icon [svgIcon]="iconsConfig.errorIcon"></mat-icon>
    </span>
  </div>
</ng-template>
