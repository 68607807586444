export const selectedNotificationSkeleton = {
  destinationIdentifier: {
    currency: '<div class="skeleton_25"></div>',
    accountNumber: '<div class="skeleton_75"></div>',
    sortCode: '<div class="skeleton_50"></div>',
  },
  address: {
    country: '<div class="skeleton_100"></div>',
  },
  name: '<div class="skeleton_100"></div>',
  createdDate: '<div class="skeleton_50"></div>',
  updatedDate: '<div class="skeleton_50"></div>',
};
