import { AlertItemConfigurationType, AlertStyleType, IconConfigType } from '../../config.type';
import { AlertPositions, DismissalTypeMobile } from '../toaster-alerts.type';

export const DEFAULT_ICON_CONFIG: { [iconType: string]: IconConfigType } = {
  leftIcon: {
    name: '',
    size: '',
    color: '',
    unit: '',
  },
  rightIcon: {
    name: 'times',
    size: '14',
    color: '#E4F8F0',
    unit: 'px',
  },
};

export const DEFAULT_ALERT_STYLES: AlertStyleType = {
  minWidth: 335,
  minWidthUnit: 'px',
  backgroundOpacity: 100,
  backGroundColor: '#E4F8F0',
  textStyle: {
    titleFontSize: 14,
    titleFontFamily: 'var(--font-family)',
    descriptionFontSize: 14,
    descriptionFontFamily: 'var(--font-family)',
    titleColor: '#000000',
    descriptionColor: '#000000',
  },
  iconConfiguration: DEFAULT_ICON_CONFIG,
};

export const DEFAULT_ALERT_CONFIGURATION: AlertItemConfigurationType = {
  behaviourSettings: {
    dismissalType: DismissalTypeMobile.CLOSE_AFTER,
    dismissAfter: '4',
    position: AlertPositions.UPPER_RIGHT,
  },
  styles: DEFAULT_ALERT_STYLES,
  htmlString: '',
};
