export enum PaymentRequestStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  PENDING = 'pending',
  CANCELLED = 'cancelled',
}

export enum PaymentApproverStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum BulkPaymentMasterStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  PARTIALLY_COMPELTED = 'partially completed',
  FAILED = 'failed',
  IN_PROGRESS = 'in progress',
  AUTHENTICATED = 'authenticated',
  CANCELLED = 'cancelled',
  REVIEW_REQUEST = 'review_request',
  PENDING_APPROVAL = 'pending approval',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum BulkPaymentBeneficiaryStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum BulkPaymentBeneficiaryExistStatus {
  EXISTING = 'existing',
  NEW = 'new',
  PENDING = 'pending',
}

export enum BulkPaymentBeneficiaryCoPStatus {
  NA = 'not_applicable',
  PENDING = 'pending',
  MATCHED = 'matched',
  APPROVED = 'approved',
}

export enum BulkPaymentScheduleType {
  INSTANT = 'instant',
  SCHEDULE = 'schedule',
}

export enum BulkPaymentTransactionStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
  DELETED = 'deleted',
  CANCELLED = ' cancelled',
}

export enum BulkPaymentStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
  DUPLICATE_RECORDS = 'duplicate records',
}

export enum ServiceProvider {
  INTEGRATED_FINANCE = 'integratedFinance',
  MODULR = 'modulr',
  ACCOMPLISH = 'accomplish',
}

export enum OtpParam {
  EditBeneficiary = 'edit-beneficiary',
  EditIntlBeneficiary = 'edit-intl-beneficiary',
  AddBeneficiary = 'add-beneficiary',
  AddIntlBeneficiary = 'add-intl-beneficiary',
  RemoveBeneficiary = 'remove-beneficiary',
  PaymentRequest = 'payment-request',
}

export enum OtpStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  FAILED = 'failed',
  MAX_ATTEMPTS = 'max_attempts_reached',
  DELETED = 'deleted',
  EXPIRED = 'expired',
}

export enum TwilioServiceType {
  MessagingService = 'Twilio Messaging Service',
  VerifyService = 'Twilio Verify Service',
  Unknown = 'Unknown Service Type',
  Invalid = 'Invalid SID',
}

export enum SEPAPaymentRailTypeEnum {
  SEPA_CREDIT_TRANSFER = 'SEPA Credit Transfer (SCT)',
  SEPA_CREDIT_TRANSFER_INSTANT = 'SEPA Credit Transfer Instant (SCT Inst)',
  TARGET2 = 'Target2',
}
