import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IconCacheService {
  private iconCache: Map<string, string> = new Map();
  private iconUrlMap: Map<string, string> = new Map();
  private fetchingIcons: Set<string> = new Set();

  // Add Subject for icon updates
  private iconLoaded = new BehaviorSubject<string>('');
  iconLoaded$ = this.iconLoaded.asObservable();

  constructor(private sanitizer: DomSanitizer) {}

  initializeIconUrls(icons: { name: string; url: string }[]): void {
    icons.forEach((icon) => {
      this.iconUrlMap.set(icon.name, icon.url);
    });
  }

  getIcon(iconName: string): string | null {
    if (this.hasIcon(iconName)) {
      return this.iconCache.get(iconName) || null;
    }

    if (!this.fetchingIcons.has(iconName)) {
      this.fetchIconInBackground(iconName);
    }

    return null;
  }

  private fetchIconInBackground(iconName: string): void {
    const url = this.iconUrlMap.get(iconName);
    if (!url || this.fetchingIcons.has(iconName)) return;

    this.fetchingIcons.add(iconName);

    fetch(url)
      .then((response) => response.text())
      .then((svg) => {
        this.setIcon(iconName, svg);
        this.fetchingIcons.delete(iconName);
        // Emit the icon name when loaded
        this.iconLoaded.next(iconName);
      })
      .catch((error) => {
        console.error(`Failed to fetch icon ${iconName}:`, error);
        this.fetchingIcons.delete(iconName);
      });
  }

  setIcon(iconName: string, svg: string): void {
    this.iconCache.set(iconName, svg);
  }

  hasIcon(iconName: string): boolean {
    return this.iconCache.has(iconName);
  }

  removeIcon(iconName: string): void {
    this.iconCache.delete(iconName);
  }

  clearCache(): void {
    this.iconCache.clear();
  }
}
