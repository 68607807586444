import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageConfigurationProvider } from '@app/finxone-web-frontend/app/Modules/zones/page-configuration.provider';
import { ConfigService } from '@app/finxone-web-frontend/app/lib/services/config-service/config-service.service';
import { FormSubmissionService } from '@app/finxone-web-frontend/app/lib/services/form-submission-service/form-submission-service.service';
import { PasswordService } from '@app/finxone-web-frontend/app/lib/services/password-service/password-service.service';
import { FormActionState } from '@app/finxone-web-frontend/app/lib/state/form-submision.state';
import {
  createPasswordFieldValidator,
  passwordMatchValidator,
} from '@app/finxone-web-frontend/app/lib/utils/password-field.utils';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import {
  BaseCssProperties,
  BaseWidgetProperties,
  PasswordFieldConfigurations,
  ProviderClass,
  WidgetNames,
  baseCssConfigStyle,
  buttonNestedWidgetConfigStyles,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { catchError, map } from 'rxjs';
import {
  FormStage,
  MultiPartFormHolderService,
} from '../../../../lib/services/multipart-holder/multipart-form-holder.service';
import { ZonePageUrlProvider } from '../../../zones/zone-url-provider';
import { FormSubmitter, PasswordValidationRules } from '../form-submitter/form-submitter.component';

@Component({
  selector: 'finxone-web-frontend-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.scss'],
})
export class PasswordForgotComponent extends FormSubmitter implements OnInit {
  public buttonContent = '';
  public renderedButtonContent = '';
  public zone = '';
  public enableSubmit = false;
  public nextPageUrl: string;
  public passwordForm: FormGroup;
  public reviewDetailsButtonStyle: baseCssConfigStyle = {};
  public newPasswordInputStyle: baseCssConfigStyle;
  public confirmPasswordInputStyle: baseCssConfigStyle;
  public inputActiveStyle: baseCssConfigStyle;
  public inputInactiveStyle: baseCssConfigStyle;
  public globalStylingClass = '';
  public isAllowedGlobalStyling = false;
  public colorTextAlignStyle: baseCssConfigStyle;
  public mergedHeadingStyle: baseCssConfigStyle;
  public passwordFieldValidators: PasswordValidationRules;
  public passwordRules: any;
  public passwordFieldConfigs: PasswordFieldConfigurations;
  public fieldTypes: { [key: string]: 'password' | 'text' } = {};

  constructor(
    private _router: Router,
    public pageConfig: PageConfigurationProvider,
    public dataIn: ProviderClass<any>,
    private configService: ConfigService,
    public override store: Store,
    public formHolderService: MultiPartFormHolderService,
    private formSubmissionService: FormSubmissionService,
    private passwordService: PasswordService,
    private urlZoneInput: ZonePageUrlProvider,
  ) {
    super(store);
    this.colorTextAlignStyle = this.configService.getSpecificBaseCssStyle(this.dataIn.attri, [
      BaseCssProperties.FONT_SIZE,
      BaseCssProperties.TEXT_BOLD,
      BaseCssProperties.TEXT_ITALIC,
      BaseCssProperties.TEXT_UNDERLINE,
      BaseCssProperties.COLOR,
      BaseCssProperties.TEXT_FONT_STYLE,
      BaseCssProperties.TEXTALIGN,
    ]);
    this.contentStyle = this.configService.getSpecificBaseCssStyle(this.dataIn.attri, [
      BaseCssProperties.BORDER,
      BaseCssProperties.BACKGROUND_COLOR,
      BaseCssProperties.MARGIN,
      BaseCssProperties.PADDING,
    ]);

    this.mergedHeadingStyle = { ...this.colorTextAlignStyle };
    this.getAndAssignInputFieldStyles(this.dataIn.attri.widgetProperties);

    this.zone = this.urlZoneInput.zoneUrlInput;
    this.fetchPasswordFieldConfig();
  }

  private fetchPasswordFieldConfig() {
    this.configService
      .getPasswordFieldConfigurations()
      .pipe(
        map((config) => {
          this.passwordFieldConfigs = config ?? {};
        }),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.createFormStage();
    this.buildForm();
    this.fetchPasswordRules();
    this.buttonContent =
      this.dataIn.widgetConfig.nestedWidgets?.['reviewDetailsButton']?.widgetProperties.textContent ?? 'Next';

    this.reviewDetailsButtonStyle = this.configService.getSpecificBaseCssStyle(
      this.getNestedWidgetConfig(this.dataIn, 'reviewDetailsButton'),
      buttonNestedWidgetConfigStyles,
    );
    this.getGlobalStyling();
  }
  fetchPasswordRules() {
    this.configService
      .fetchPasswordRules()
      .pipe(
        map((rules) => {
          this.passwordRules = rules.policy;
          this.setValidatorsFromPasswordRules();
        }),
        catchError((error) => {
          this.passwordRules = '';
          throw new Error('failed to fetch password rules', error);
        }),
      )
      .subscribe();
  }

  private getGlobalStyling() {
    if (
      this.dataIn.widgetConfig.nestedWidgets?.['reviewDetailsButton'].widgetProperties?.[
        'globalStylingClass'
      ] &&
      this.dataIn.widgetConfig.nestedWidgets?.['reviewDetailsButton'].widgetProperties?.['globalStyling']
    ) {
      this.globalStylingClass =
        this.dataIn.widgetConfig.nestedWidgets?.['reviewDetailsButton'].widgetProperties?.[
          'globalStylingClass'
        ] ?? '';
      this.isAllowedGlobalStyling =
        this.dataIn.widgetConfig.nestedWidgets?.['reviewDetailsButton'].widgetProperties?.['globalStyling'] ??
        false;
    }
  }

  private createFormStage(): void {
    const formStage = new FormStage({
      position: 0,
      name: WidgetNames.PASSWORD_FORGOT,
      route: '',
      form: new FormGroup({
        newPassword: new FormControl('', Validators.required),
        confirmPassword: new FormControl('', Validators.required),
      }),
    });
    this.fieldTypes['newPassword'] = 'password';
    this.fieldTypes['confirmPassword'] = 'password';
    this.formSubmissionService.addCurrentPageFormGroups(formStage.form);
    this.formHolderService.config.addStage(formStage);
  }

  public get keysOfPasswordRules() {
    return Object.keys(this.passwordRulesMessages);
  }

  private setValidatorsFromPasswordRules() {
    this.passwordFieldValidators = this.parsePasswordPolicy(this.passwordRules);
    if (this.passwordFieldValidators?.maxLength) {
      this.passwordForm
        .get('newPassword')
        ?.addValidators(Validators.maxLength(this.passwordFieldValidators.maxLength));
      this.passwordForm
        .get('confirmPassword')
        ?.addValidators(Validators.maxLength(this.passwordFieldValidators.maxLength));
    }
    if (this.passwordFieldValidators?.length) {
      this.passwordForm
        .get('newPassword')
        ?.addValidators(Validators.minLength(this.passwordFieldValidators.length));
      this.passwordForm
        .get('confirmPassword')
        ?.addValidators(Validators.minLength(this.passwordFieldValidators.length));
    }
    if (
      this.passwordFieldValidators?.digits ||
      this.passwordFieldValidators?.lowerCase ||
      this.passwordFieldValidators?.specialChars ||
      this.passwordFieldValidators?.upperCase
    ) {
      this.passwordForm
        .get('newPassword')
        ?.addValidators(createPasswordFieldValidator(this.passwordFieldValidators));
      this.passwordForm
        .get('confirmPassword')
        ?.addValidators(createPasswordFieldValidator(this.passwordFieldValidators));
    }
  }

  private buildForm(): void {
    const stage = this.formHolderService.config.getStage(WidgetNames.PASSWORD_FORGOT);
    this.passwordForm = stage.getForm();
    this.passwordForm.addValidators([passwordMatchValidator]);
  }

  public onSubmit(): void {
    if (this.passwordForm.valid) {
      const formValues: Record<string, any> = this.passwordForm.value;

      this.formSubmissionService
        .submitIndividualForm(formValues, '', FormActionTypeEnum.PASWORD_CHANGE)
        .then((success) => {
          if (success) {
            this.store
              .select(FormActionState.getFormActionState)
              .pipe(
                map((formActionStateData) => {
                  return formActionStateData[FormActionTypeEnum.PASWORD_CHANGE]?.formData;
                }),
              )
              .subscribe({
                next: ({ currentPassword, newPassword, confirmPassword }) => {
                  if (this.enableSubmit) {
                    if (this.dataIn.widgetConfig.name === WidgetNames.PASSWORD_UPDATE) {
                      this.passwordService
                        .resetPassword(currentPassword, newPassword, confirmPassword)
                        .subscribe({
                          next: () => {
                            this._router.navigate(['/zones/profile/change-password-success']);
                          },
                          error: (err) => {
                            console.error(err);
                          },
                        });
                    } else {
                      const splitUrl = window.location.href.split('/');
                      const token = splitUrl[splitUrl.length - 1];
                      this.passwordService.updatePassword(token, newPassword, confirmPassword).subscribe({
                        next: () => {
                          this._router.navigate(['/zones/forgot-password/pass-reset-validation']);
                        },
                        error: (err) => {
                          console.error(err);
                        },
                      });
                    }
                    this.enableSubmit = false;
                  }
                },
              });

            let name = '';
            Object.values(formValues).forEach((value: string) => {
              name = name + value + ' ';
            });
          } else {
            return;
          }
        });
    } else {
      this.passwordForm.markAllAsTouched();
    }
  }

  private getAndAssignInputFieldStyles(widgetProperties: BaseWidgetProperties) {
    const { baseStyle, activeStyle, inactiveStyle } =
      this.configService.getInputFieldCssStyles(widgetProperties);
    this.inputActiveStyle = {
      ...baseStyle,
      ...activeStyle,
    };
    this.inputInactiveStyle = {
      ...inactiveStyle,
      ...activeStyle,
    };
    this.newPasswordInputStyle = this.confirmPasswordInputStyle = this.inputInactiveStyle;
  }

  public updateInputFieldCurrentStyles(event: boolean, fieldName: string) {
    if (fieldName === 'newPassword') {
      this.newPasswordInputStyle = event ? this.inputActiveStyle : this.inputInactiveStyle;
    }
    if (fieldName === 'confirmPassword') {
      this.confirmPasswordInputStyle = event ? this.inputActiveStyle : this.inputInactiveStyle;
    }
  }

  public validateFieldForSuccessHighlighting(passwordRule: string): boolean {
    const control = this.passwordForm.get('newPassword');
    switch (passwordRule) {
      case 'minLength':
        return !!(
          !control?.hasError(passwordRule) &&
          control?.value.length >= (this.passwordFieldValidators?.length ?? 0)
        );
      case 'maxLength':
        return !!(
          !control?.hasError(passwordRule) &&
          control?.value.length >= (this.passwordFieldValidators?.length ?? 8) &&
          control?.value.length <= (this.passwordFieldValidators?.maxLength ?? 8)
        );
      default:
        return !!(!control?.hasError(passwordRule) && control?.value.length !== 0);
    }
  }

  public validateFieldForErrorHighlighting(passwordRule: string): boolean {
    const control = this.passwordForm.get('newPassword');
    switch (passwordRule) {
      case 'minLength':
        return !!(
          !control?.hasError(passwordRule) &&
          control?.dirty &&
          control?.value.length < (this.passwordFieldValidators?.length ?? 8)
        );
      case 'maxLength':
        return !!(
          !control?.hasError(passwordRule) &&
          control?.dirty &&
          control?.value.length > (this.passwordFieldValidators?.maxLength ?? 20)
        );
      default:
        return !!(control?.hasError(passwordRule) && control?.dirty && control?.value.length !== 0);
    }
  }

  public togglePasswordFieldType(fieldName: string, value: 'password' | 'text') {
    this.fieldTypes[fieldName] = value;
  }

  public get formIsValid(): boolean {
    const formData = this.store.selectSnapshot(
      FormActionState.getFormActionStateWithId(FormActionTypeEnum.PASWORD_CHANGE),
    )?.formData;
    return !!(formData?.currentPassword?.length && this.passwordForm.valid) || false;
  }
}
