export const accountSelectionItemConfig = {
  headerTemplate: '',
  textInputTemplate: `<div class="account-selection-card w-100"
  style="
  background: rgba(255, 255, 255, 1);
  ">
    <div class="top-section">
      <div class="account-name">
        {{ customerName }}
      </div>
      <div class="currency-amount">
        {{ currencyPipe availableBalance currencyCode=currency display="symbol-narrow" }}
      </div>
    </div>

    <div class="bottom-section">
      <div class="label">
      {{ accountIdentifier.[0].accountNumber }}
      </div>
      <div class="label account-sortcode">
      {{ accountIdentifier.[0].sortCode }}
      </div>
    </div>
  </div>
    `,
  textInputSelectedTemplate: `<div class="account-selection-card w-100 active">
    <div class="top-section">
      <div class="account-name">
        {{ customerName }}
      </div>
      <div class="currency-amount">
        {{ currencyPipe availableBalance currencyCode=currency display="symbol-narrow" }}
      </div>
    </div>

    <div class="bottom-section">
      <div class="label">
        {{ accountIdentifier.[0].sortCode }}
      </div>
      <div class="label account-sortcode">
      {{ accountIdentifier.[0].accountNumber }}
      </div>
    </div>
  </div>
    `,
};

export const selectedAccountCustomerNameTemplate = `<div>
{{ customerName }}
</div>`;

export const selectedAccountItemTemplateGreyBgColorStyle = `
"
      border-color: rgb(235, 235, 235);
      border-width: 1px;
      border-style: solid;
      background-color: rgba(242, 244, 247, 1);
      border-radius: 5px;
      "`;

export const selectedAccountItemTampleteWhiteBgColorStyle = `
"
    border-color: rgba(197, 197, 197, 1);
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    background: rgba(255, 255, 255, 1);
      "`;

export const selectedAccountItemTemplate = (style: string) => `
<div class="account-selection-card w-100"
      style= ${style}
>
    <div class="top-section">
      <div class="account-name">
      {{ customerName }}
      </div>
      <div class="currency-amount">
        {{ currencyPipe availableBalance currencyCode=currency display="symbol-narrow" }}
      </div>
    </div>

    <div class="bottom-section">
      <div class="label">
        {{ accountNumber }}
      </div>
      <div class="label account-sortcode">
        {{ sortCode }}
      </div>
    </div>
  </div>`;

export const totalEnteredAmountBackgroundStyle = `
      border-color: rgb(235, 235, 235);
      border-width: 1px;
      border-style: solid;
      background-color: rgba(242, 244, 247, 1);
      width: 100%;
  `;

export const paymentProcessingTotalEnteredAmountBackgroundStyle = `
      width: 255px;
      background: rgb(241 241 241);
  `;

export const totalEnteredAmountTemplate = (style: string, type?: string) => {
  return `
  <div class="total-amount-content"
        style="
        height: 108px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        ${style}
        ">
    <div class="label" style="margin-bottom: 5px;">Total Amount</div>
    <div class="amount"
        style="
        font-family: Metropolis;
        font-size: 30px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0em;
        ">
      ${
        type === 'international'
          ? '{{ currencyPipe paymentRequest.totalAmount currencyCode=paymentRequest.currency }}'
          : ' {{ currencyPipe paymentRequest.amount currencyCode=paymentRequest.currency }}'
      }
    </div>
  </div>`;
};

export const paymentSummaryBeneficiaryDetailsTemplate = (formStateName: string) => `
<div class="w-100 h-100">
<div class='is-vertical-align' style='align-items: flex-start;'>
    <div class="ben-label mb-1">
    <b>Full Name</b>
    </div>
    <div class="ben-value">
    <b> {{ ${formStateName}.name }} </b>
    </div>
</div>
<hr class="my-4 mx-1">
<div>
  <div
    class="is-vertical-align"
    style="padding-right: 66px; justify-content: space-between"
  >
    {{#if (neq currency 'EUR')}}

        <div class="sort-account-content mb-3 is-space-between">

          <div>
            <div class="ben-label mb-1">Account Number</div>
            <div class="ben-value">{{ ${formStateName}.destinationIdentifier.accountNumber }}</div>
          </div>

          {{#if ${formStateName}.destinationIdentifier.sortCode}}

            <div>
              <div class="ben-label mb-1">Sort Code</div>
              <div class="ben-value">{{ ${formStateName}.destinationIdentifier.sortCode }}</div>
            </div>

          {{/if}}
        </div>

      {{else if (eq currency 'EUR')}}

      {{#if ${formStateName}.destinationIdentifier.iban}}

        <div class="is-vertical-align align-left mt-2">
          <div class="ben-label mb-1">IBAN</div>
          <div class="ben-value">{{ ${formStateName}.destinationIdentifier.iban }}</div>
        </div>

      {{/if}}

      {{#if ${formStateName}.destinationIdentifier.bic}}

        <div class="is-vertical-align align-left mt-2">
          <div class="ben-label mb-1">BIC</div>
          <div class="ben-value">{{ ${formStateName}.destinationIdentifier.bic }}</div>
        </div>

      {{/if}}

      {{#if sepaFormState.paymentRail}}

        <div class="is-vertical-align align-left mt-2">
          <div class="ben-label mb-1">Payment Rail</div>
          <div class="ben-value">{{ sepaFormState.paymentRail }}</div>
        </div>

      {{/if}}

      {{else}}

    {{/if}}

    <div class="is-vertical-align align-left mt-2">
      <div class="ben-label mb-1">Payment Reference</div>
      <div class="ben-value">{{ paymentRequest.paymentReference }}</div>
    </div>
  </div>
</div>
</div>
`;

export const paymentProcessingWaitTextTemplate = `<div
style="
font-family: Metropolis;
font-size: 24px;
font-weight: 700;
line-height: 32px;
letter-spacing: 0em;
text-align: center;
"
>
Processing.
</div>`;

export const accountNameDoesNotMatchTemplate = (textContent: string) => `
<div class="w-100"
              style="
              font-weight: 700;
              line-height: 30px;
            ">
              <p>
              ${textContent}
              </p>
</div>`;

export const accountCredentialsDoesNotMatchTemplate = `
<div class="w-100"
            style="
              display: flex;
              align-items: baseline;
              justify-content: center;
              line-height: 24px;
            ">
  <p
  style="margin: 0;"
  >
  Account credentials does not match
  </p>
</div>`;

export const accountCredentialsDoesNotMatchDescriptionTemplate = (textContent: string) => `
<div class="w-100"
    style="
      line-height: 24px;
">
  <p>
  ${textContent}
  </p>

  <p style="
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  "
  class="primary-label">
  You entered
  </p>
</div>`;

export const bankSuggestedBeneficiaryNameTemplate = `
<div class="w-100"
 style="
    font-weight: 700;
    margin: 0 30px;
    padding: 25px;
    background-color: rgba(255, 228, 228, 1);
    border-radius: 5px;
  ">
{{ copResponse.name }}
</div>`;

export const userEnteredBeneficiaryNameTemplate = `
<div class="w-100"
 style="
    font-weight: 700;
    margin: 0 30px;
    padding: 25px;
    background-color: rgba(255, 228, 228, 1);
    border-radius: 5px;
  ">
{{ beneficiaryDetails.name }}
</div>`;
