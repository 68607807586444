import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import {
  InternationalPaymentStatusScreen,
  PaymentStatusScreen,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { map, take } from 'rxjs';
import { ClearFormDataActionWithId } from '../../../actions/form-submission.action';
import { CreatePayment, PaymentDeclined, PaymentSuccess } from '../../../actions/payment.action';
import { TransferMoneyRequest } from '../../../services/account-service/account.type';
import { AccountState } from '../../../state/account.state';
import { FormActionState } from '../../../state/form-submision.state';

export function submitDomesticPaymentRequest(store: Store, actions: Actions, router: Router): void {
  let formStateData = store.selectSnapshot(FormActionState.getFormActionState).response?.formData;
  const activeAccountId = store.selectSnapshot(AccountState.getCurrentAccountId);
  const sepaFormState = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.SEPA_PAYMENT),
  )?.formData;

  formStateData = {
    ...formStateData,
    sourceAccountId: activeAccountId,
    paymentRailOption: sepaFormState?.paymentRail,
  };
  if (formStateData) {
    store.dispatch(new CreatePayment(formStateData as TransferMoneyRequest));

    actions
      .pipe(
        ofActionDispatched(PaymentSuccess),
        take(1),
        map(() => {
          store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.SEPA_PAYMENT));
          store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.SEPA_PAYMENT_RAIL_FLAG));
          navigateToPaymentStatusScreen(PaymentStatusScreen.PAYMENT_SUCCESS, router);
        }),
      )
      .subscribe();

    actions
      .pipe(
        ofActionDispatched(PaymentDeclined),
        take(1),
        map(() => {
          store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.SEPA_PAYMENT));
          store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.SEPA_PAYMENT_RAIL_FLAG));
          navigateToPaymentStatusScreen(PaymentStatusScreen.PAYMENT_DECLINED, router);
        }),
      )
      .subscribe();
  }
}

export function navigateToPaymentStatusScreen(
  status: PaymentStatusScreen | InternationalPaymentStatusScreen,
  router: Router,
): void {
  router.navigateByUrl(`/zones/payments/${status}`, {
    replaceUrl: true,
  });
}
