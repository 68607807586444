import { Router } from '@angular/router';
import { BeneficiariesSuccessCodes } from '@app/translations';
import { OtpParam } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  APP_ZONES,
  BaseWidgetProperties,
  UiZoneWidgetAttributeConfig,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { AccountService } from '../../../services/account-service/account-service.service';
import { GraphqlServiceService } from '../../../services/graphql-service/graphql-service.service';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import { FormActionState } from '../../../state/form-submision.state';
import { ProjectSettingsState } from '../../../state/project-settings.state';
import { RoleState } from '../../../state/role.state';
import { findAndShowAlertFromCode } from '../../utils';
import { formatNameAsUrl } from '../../zone-url.utils';
import { redirectToPage } from '../cta-button-actions.utils';
export function removeBeneficiaryFromAccount(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  _: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
  ____: MetadataService,
  __?: GraphqlServiceService,
  ___?: UiZoneWidgetAttributeConfig,
  accountService?: AccountService,
) {
  const beneficiaryId = store.selectSnapshot(FormActionState.getFormActionState)?.response?.formData?.bid;
  if (beneficiaryId) {
    accountService
      ?.removeBeneficiary(beneficiaryId)
      .pipe(
        tap((response) => {
          if (response) {
            findAndShowAlertFromCode(store, alertHandlerService, [
              BeneficiariesSuccessCodes.REMOVE_BENEFICIARY,
            ]);
            redirectToPage(router, widgetProperties);
          } else {
            alertHandlerService.showAlertFn('error', 'An unexpected error occurred, please try again');
          }
        }),
      )
      .subscribe();
  } else {
    alertHandlerService.showAlertFn('error', 'Selected Beneficiary not found, please try again');
  }
}

export function callRemoveBeneficiaryAPI(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  _: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
  ____: MetadataService,
  __?: GraphqlServiceService,
  ___?: UiZoneWidgetAttributeConfig,
  accountService?: AccountService,
) {
  const projectSettingsData = store.selectSnapshot(ProjectSettingsState.getProjectSettings);
  const roleData = store.selectSnapshot(RoleState.getRole);

  if (projectSettingsData?.smsAuthenticationEnabled) {
    if (projectSettingsData.smsAuthenticationExclusion?.['removeBeneficiary']?.includes(roleData?.role)) {
      // previous scenario
      removeBeneficiaryFromAccount(
        widgetProperties,
        store,
        _,
        router,
        alertHandlerService,
        ____,
        __,
        ___,
        accountService,
      );
    } else {
      // OTP page redirection
      router.navigateByUrl(
        `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/authentication-sms?otp=${OtpParam.RemoveBeneficiary}`,
      );
    }
  } else {
    // previous scenario
    removeBeneficiaryFromAccount(
      widgetProperties,
      store,
      _,
      router,
      alertHandlerService,
      ____,
      __,
      ___,
      accountService,
    );
  }
}
