import { ChangeDetectorRef, Component, effect, ElementRef, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfigService } from '@app/finxone-web-frontend/app/lib/services/config-service/config-service.service';
import { baseCssConfigStyle, ButtonTypes } from '@finxone-platform/shared/sys-config-types';

@Component({
  selector: 'finx-button',
  templateUrl: './finx-button.component.html',
  styleUrls: ['./finx-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FinxButtonComponent),
      multi: true,
    },
  ],
})
export class FinxButtonComponent implements ControlValueAccessor {
  isFocused = false;
  @Input() id: string;
  @Input() type = 'primary';
  @Input() width = '100%';
  @Input() class = '';
  @Input() labeltext = '';
  @Input() disabled = false;
  @Input() rounded = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() value: any = '';
  @Input() buttonStyle:
    | {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [klass: string]: any;
      }
    | null
    | undefined;
  @Input() leftIcon = '';
  @Input() rightIcon = '';
  @Input() public rightIconSize?:
    | {
        width: string;
        height: string;
      }
    | baseCssConfigStyle;
  @Input() public leftIconSize?:
    | {
        width: string;
        height: string;
      }
    | baseCssConfigStyle;
  @Input() public rightIconClass = '';
  @Input() public isAllowedGlobalStyling = false;

  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-empty-function
  onModelChange: Function = () => {};
  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-empty-function
  onModelTouched: Function = () => {};

  public borderRadiusClass = '';

  private cssVars: Record<string, string> = {};
  private buttonTypes = ButtonTypes;
  constructor(
    public el: ElementRef,
    private cd: ChangeDetectorRef,
    private readonly configService: ConfigService,
  ) {
    effect(() => {
      const globalCssVariables = this.configService.globalCssVariables();
      this.cssVars = globalCssVariables;
      if (this.class && this.isAllowedGlobalStyling) {
        // get the border radius class and icon from theme  css
        this.getStyleValuesForButtonTypes(this.class);
      }
    });
  }

  private getStyleValuesForButtonTypes(className: string) {
    const buttonClasses = className.split(' ');
    // Create a regex pattern from the button types
    const buttonTypePattern = new RegExp(Object.values(this.buttonTypes).join('|'), 'i');
    // Find the first matching button type
    const matchedButtonType = buttonClasses.find((classItem) => buttonTypePattern.test(classItem));
    switch (matchedButtonType) {
      case this.buttonTypes.PRIMARY:
      case this.buttonTypes.TERTIARY:
        this.borderRadiusClass = this.getCssVariableValue('--primaryButtonBorderRadiusClass');
        break;
      case this.buttonTypes.SECONDARY:
        this.borderRadiusClass = this.getCssVariableValue('--secondaryButtonBorderRadiusClass');
        break;
      case this.buttonTypes.MENU:
        //get the icon name from root styles
        this.rightIcon = this.getCssVariableValue('--menuButtonIcon');
        this.borderRadiusClass = this.getCssVariableValue('--menuButtonBorderRadiusClass');
        break;
      default:
        this.borderRadiusClass = '';
        break;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(value: any): void {
    this.value = value;
    this.cd.markForCheck();
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }
  onClick = () => {
    this.isFocused = false;
  };

  private getCssVariableValue(variableName: string): string {
    return this.cssVars[variableName] ?? '';
  }
}
