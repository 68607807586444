/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  APP_ZONES,
  AccountInfoType,
  AccountStatusType,
  BeneficiaryRequirementsResponse,
  BulkPaymentResultList,
  CardInfoType,
  CurrencyExchangeTransactionType,
  ExchangeCurrencyTransactionsResponse,
  PaginatedResponse,
  SecureCardTokenInfo,
  TransactionType,
} from '@finxone-platform/shared/sys-config-types';
import {
  PaymentRequestDetailResponse,
  PaymentRequestListResponse,
  PaymentRequestPayload,
  PaymentRequestSummaryCounts,
} from '@finxone-platform/shared/utils';
import { Store } from '@ngxs/store';
import { Buffer } from 'buffer';
import { serviceUnavailable } from 'libs/shared/default-templates/src/lib/default-templates/cop-no-match-reasons';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { SetCopResponseStateReason } from '../../actions/cop-response.action';
import { AddProgressBarStack, RemoveProgressBarStack } from '../../actions/progress-bar.action';
import { PaymentRequestSummary } from '../../state/notifications.state';
import {
  UploadFileResponse,
  uploadFileRequest,
} from '../../utils/cta-button-actions/bulk-payments/bulk-payments.utils';
import { formatNameAsUrl } from '../../utils/zone-url.utils';
import { ConfigService } from '../config-service/config-service.service';
import {
  AccountDebitCreditSummary,
  AccountDebitCreditUpdateSummary,
  AddAccountRequest,
  AddBeneficiaryRequest,
  BulkPaymentHistoryResponse,
  CheckPayeeResponse,
  DailyBalanceValue,
  GetBeneficiaryResponse,
  GetCardRequest,
  GetCardResponse,
  GetTotalWealthResponse,
  Payee,
  PaymentRequestUpdateRequest,
  PaymentRequestorRequest,
  PaymentRequestorResponse,
  TransactionDetailResponse,
  TransactionInfoType,
  Transfer,
  TransferMoneyInternationalResponse,
  TransferMoneyRequest,
  TransferMoneyResponse,
  TransferReason,
  UpdateBeneficiaryRequest,
  updateTransactionRequest,
} from './account.type';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private baseUrl = '';
  accounts$: Observable<AccountInfoType[]>;

  private _current_transaction = new BehaviorSubject<any>('');
  readonly $current_transaction = this._current_transaction.asObservable();
  current_transaction: Transfer | undefined;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private router: Router,
    private store: Store,
  ) {
    this.configService.getApi('account_service').subscribe((response) => {
      this.baseUrl = response;
    });
  }

  public getBeneficiaryRequirements(
    countryCode: string,
    currencyCode: string,
  ): Observable<BeneficiaryRequirementsResponse> {
    return this.http
      .get<BeneficiaryRequirementsResponse>(
        `${this.baseUrl}/beneficiaries/beneficiary-bank-account-requirements`,
        {
          params: {
            countryCode: countryCode,
            currencyCode: currencyCode,
          },
        },
      )
      .pipe(
        catchError<any, Observable<boolean>>((_err) => {
          console.error(`Error getting beneficiary bank requirements: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  getMonthlySummary(accountId: string): Observable<AccountDebitCreditSummary> {
    return this.http.get(`${this.baseUrl}/account/monthly-summary?accountId=${accountId}`).pipe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      catchError<any, Observable<boolean>>((_err, _caught) => {
        console.error(`Error fetching monthy summary for accountId ${accountId}: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }
  getUpdatedTrasanctionSummary(
    payload: updateTransactionRequest,
  ): Observable<AccountDebitCreditUpdateSummary> {
    return this.http
      .post<TransferMoneyResponse>(`${this.baseUrl}/account/update-transactions-summary`, payload)
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        catchError<any, Observable<boolean>>((_err, _caught) => {
          console.error(`Error fetching monthy summary for accountId: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  getAccountCard(accountId: string, page = 1, size = 20): Observable<PaginatedResponse<CardInfoType>> {
    return this.http
      .get<PaginatedResponse<CardInfoType>>(
        `${this.baseUrl}/cards?accountId=${accountId}&page=${page}&size=${size}&`,
      )
      .pipe(
        catchError<any, Observable<boolean>>((_err) => {
          console.error(`Error getting account cards for accountId ${accountId}: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  getTransactionSummary(
    accountId: string,
    dateFrom?: string | null,
    dateTo?: string | null,
    page = 1,
    limit = 10,
    transactionType: CurrencyExchangeTransactionType | TransactionType = TransactionType.INCOMING,
    currency = '',
  ): Observable<PaginatedResponse<TransactionInfoType | ExchangeCurrencyTransactionsResponse>> {
    let url;
    let otherParams = '';
    if (dateFrom) {
      otherParams = `&from=${dateFrom}&to=${dateTo}`;
    }

    if (transactionType === TransactionType.ALL) {
      url = `${this.baseUrl}/account/${accountId}/transactions?page=${page}&size=${limit}${otherParams}`;
    } else if (transactionType === TransactionType.OUTGOING) {
      url = `${this.baseUrl}/account/${accountId}/outgoing-transactions?page=${page}&size=${limit}${otherParams}`;
    } else if (
      CurrencyExchangeTransactionType.CURRENCYALL === transactionType ||
      CurrencyExchangeTransactionType.CURRENCYINCOMING === transactionType ||
      CurrencyExchangeTransactionType.CURRENCYOUTGOING == transactionType
    ) {
      const type =
        CurrencyExchangeTransactionType.CURRENCYOUTGOING === transactionType
          ? TransactionType.OUTGOING
          : TransactionType.INCOMING;
      url = `${this.baseUrl}/exchanges/${accountId}/transactions?page=${page}&size=${limit}&type=${type}&currency=${currency}`;
    } else {
      url = `${this.baseUrl}/account/${accountId}/incoming-transactions?page=${page}&size=${limit}${otherParams}`;
    }
    return this.http
      .get<PaginatedResponse<TransactionInfoType | ExchangeCurrencyTransactionsResponse>>(url)
      .pipe(
        catchError<any, Observable<boolean>>((_err) => {
          console.error(
            `Error getting transaction summary for accountId ${accountId}: ${JSON.stringify(_err)}`,
          );
          throw _err;
        }),
      );
  }

  checkPayee(checkPayeeRequest: Payee, beneficiaryFlowParam: string): Observable<CheckPayeeResponse> {
    return this.http.post<Payee>(`${this.baseUrl}/beneficiaries/check-payee`, checkPayeeRequest).pipe(
      catchError<any, Observable<boolean>>((_err: HttpErrorResponse) => {
        if (_err.status === 503) {
          this.store.dispatch(new SetCopResponseStateReason(serviceUnavailable));
          this.router.navigateByUrl(
            `/zones/${formatNameAsUrl(
              APP_ZONES.PAYMENT,
            )}/review-information-no-match-cop?flow=${beneficiaryFlowParam}`,
          );
        }
        console.error(`Error needs writing properly: ${JSON.stringify(_err.message)}`);
        throw _err;
      }),
    );
  }

  addBeneficiary(addBeneficiaryRequest: AddBeneficiaryRequest): Observable<GetBeneficiaryResponse> {
    return this.http.post<GetBeneficiaryResponse>(`${this.baseUrl}/beneficiaries`, addBeneficiaryRequest);
  }

  // updateBeneficiary(
  //   request: UpdateBeneficiaryRequest,
  // ): Observable<GetBeneficiaryResponse> {
  //   return this.http.patch<UpdateBeneficiaryRequest>(
  //     `${this.baseUrl}/beneficiaries`,
  //     request,
  //   );
  // }

  addCurrencyAccount(currency: { currency: string }): Observable<AccountInfoType> {
    return this.http.post<AccountInfoType>(`${this.baseUrl}/account`, currency);
  }

  removeBeneficiary(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseUrl}/beneficiaries/${id}`);
  }

  updateBeneficiary(id: string, payload: UpdateBeneficiaryRequest): Observable<GetBeneficiaryResponse> {
    return this.http.patch<GetBeneficiaryResponse>(`${this.baseUrl}/beneficiaries/${id}`, payload);
  }

  getAccounts(params: AccountRepositorySearchParams): Observable<PaginatedResponse<AccountInfoType>> {
    return this.http
      .get<PaginatedResponse<AccountInfoType>>(`${this.baseUrl}/account/list`, {
        params: {
          ...params,
          status: params.status ?? [
            AccountStatusType.inactive,
            AccountStatusType.closed,
            AccountStatusType.declined,
          ],
          page: params.page ?? 1,
          size: params.size ?? 10,
        },
      })
      .pipe(
        map((v: PaginatedResponse<AccountInfoType>) => {
          return v;
        }),
        catchError<PaginatedResponse<AccountInfoType>, Observable<PaginatedResponse<AccountInfoType>>>(
          (_err, _caught) => {
            console.error(`Error getting accounts: ${JSON.stringify(_err)}`);
            throw _err;
          },
        ),
      );
  }

  public getTotalWealth(baseCurrency: string): Observable<GetTotalWealthResponse> {
    return this.http.get<any>(this.baseUrl + `/wealth/total/${baseCurrency}`);
  }

  getBeneficiary(
    page = 1,
    size = 100,
    query?: string,
    currency?: string,
  ): Observable<PaginatedResponse<GetBeneficiaryResponse>> {
    let params:
      | HttpParams
      | {
          [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        } = {
      page: page,
      size: size,
      sortKey: 'name',
      sortOrder: 'ASC',
    };

    if (query) {
      params = {
        ...params,
        query: query,
      };
    }
    if (currency) {
      params = {
        ...params,
        currency: currency,
      };
    }

    return this.http.get<PaginatedResponse<GetBeneficiaryResponse>>(`${this.baseUrl}/beneficiaries`, {
      params: params,
    });
  }

  getBeneficiaryDetail(bid: string): Observable<GetBeneficiaryResponse> {
    return this.http.get<GetBeneficiaryResponse>(`${this.baseUrl}/beneficiaries/${bid}`);
  }

  transferMoney(payload: TransferMoneyRequest): Observable<TransferMoneyResponse> {
    payload.amount = +payload.amount;
    return this.http.post<TransferMoneyResponse>(`${this.baseUrl}/payments`, payload).pipe(
      map((res: TransferMoneyResponse) => {
        this.router.navigate(['/zones/payments/transfer-confirmed']);
        return res;
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      catchError<any, Observable<boolean>>((_err, _caught) => {
        console.error(`Failed to process payment ${JSON.stringify(payload)}: ${JSON.stringify(_err)}`);
        setTimeout(() => {
          this.router.navigate(['/zones/payments/transfer-failed']);
        }, 3000);
        throw _err;
      }),
    );
  }

  transferMoneyInternational(payload: TransferMoneyRequest): Observable<TransferMoneyInternationalResponse> {
    payload.amount = +payload.amount;
    return this.http.post<TransferMoneyInternationalResponse>(`${this.baseUrl}/payments`, payload).pipe(
      map((res: TransferMoneyInternationalResponse) => {
        return res;
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      catchError<any, Observable<boolean>>((_err, _caught) => {
        console.error(`Failed to process payment ${JSON.stringify(payload)}: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  makePaymentRequest(payload: PaymentRequestorRequest): Observable<PaymentRequestorResponse> {
    payload.amount = +payload.amount;
    return this.http.post<PaymentRequestorResponse>(`${this.baseUrl}/payments/request`, payload).pipe(
      map((res: PaymentRequestorResponse) => {
        return res;
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      catchError<any, Observable<boolean>>((_err, _caught) => {
        console.error(`Failed to process payment ${JSON.stringify(payload)}: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  updatePaymentRequest(payload: PaymentRequestUpdateRequest): Observable<PaymentRequestDetailResponse> {
    return this.http
      .patch<PaymentRequestDetailResponse>(`${this.baseUrl}/payments/request/${payload.id}`, payload)
      .pipe(
        map((res: PaymentRequestDetailResponse) => {
          return res;
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        catchError<any, Observable<boolean>>((_err, _caught) => {
          console.error(`Failed to process payment ${JSON.stringify(payload)}: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  checkWebhookNotification(): Observable<string> {
    return this.http.get<string>(this.baseUrl + '/account/notification-check').pipe(
      map((accounts: string) => {
        //console.log(accounts, 'accounts');
        return accounts;
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      catchError<any, Observable<string>>((_err, _caught) => {
        console.error(`Error while checking webhook notification:`);
        throw _err;
      }),
    );
  }

  getChartData(parms: string): Observable<DailyBalanceValue[]> {
    return this.http.get<DailyBalanceValue[]>(this.baseUrl + '/account/daily-account-balance?' + parms);
  }

  setRoleInfo(token: string) {
    this.configService.setRoleInfo(token);
  }

  updateDeviceTokenOnOrgChanged(deviceToken: { tokens: string[] }): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/notifications/subscription-token`, deviceToken);
  }

  getAccountDataByUserId(id: string): Observable<AccountInfoType[]> {
    return this.http.get<AccountInfoType[]>(this.baseUrl + '/account/list?userId=' + id);
  }

  addAccount(addAccountRequest: AddAccountRequest): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/account/${addAccountRequest.userId}`, addAccountRequest);
  }

  getCards(payload: GetCardRequest): Observable<GetCardResponse> {
    return this.http.get<GetCardResponse>(this.baseUrl + '/cards', {
      params: payload,
    });
  }

  getSecureToken(publicKey: string): Observable<SecureCardTokenInfo> {
    console.log(publicKey, 'publicKey');
    const payload = {
      accountId: 'A1225NNK',
      cardId: 'V120012MTR',
      publicKey: publicKey,
    };
    return this.http.post<SecureCardTokenInfo>(this.baseUrl + '/cards/secure-details', payload).pipe(
      map((res: SecureCardTokenInfo) => {
        console.log(res, 'Secure Res');
        return res;
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      catchError<any, Observable<boolean>>((_err, _caught) => {
        console.error(`Failed to process token ${JSON.stringify(payload)}: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }
  async getRSAKeys(): Promise<{ publicKey: string; keyPair: CryptoKeyPair }> {
    this.store.dispatch(new AddProgressBarStack({ uniqueId: 'getRSAKeys' }));
    const keyPair = await crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 4096,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: 'SHA-256',
      },
      true,
      ['encrypt', 'decrypt'],
    );
    const exported = await crypto.subtle.exportKey('spki', keyPair.publicKey);
    const exportedArray = new Uint8Array(exported);

    // Convert the array of numbers to a Base64-encoded string using Buffer
    const base64String = Buffer.from(exportedArray).toString('base64');
    this.store.dispatch(new RemoveProgressBarStack({ uniqueId: 'getRSAKeys' }));
    return { publicKey: base64String, keyPair: keyPair };
  }

  async decryptAccessToken(
    encryptedToken: string,
    encryptedSymmetricKey: string,
    encodedIv: string,
    privateKey: CryptoKey,
  ) {
    this.store.dispatch(new AddProgressBarStack({ uniqueId: 'decryptAccessToken' }));
    const decodedEncryptedSymmetricKey = this.base64ToUint8Array(encryptedSymmetricKey);
    const decryptedSymmetricKey = await crypto.subtle.decrypt(
      {
        name: 'RSA-OAEP',
      },
      privateKey,
      decodedEncryptedSymmetricKey,
    );
    const aesKey = await crypto.subtle.importKey('raw', decryptedSymmetricKey, 'AES-GCM', true, [
      'encrypt',
      'decrypt',
    ]);
    const decodedIv = this.base64ToUint8Array(encodedIv);
    const decodedEncryptedToken = this.base64ToUint8Array(encryptedToken);
    const rawDecryptedToken = await crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: decodedIv,
      },
      aesKey,
      decodedEncryptedToken,
    );
    this.store.dispatch(new RemoveProgressBarStack({ uniqueId: 'decryptAccessToken' }));
    return new TextDecoder('utf-8').decode(new DataView(rawDecryptedToken));
  }

  base64ToUint8Array(base64: string) {
    const binaryString = window.atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }
  addCard(addCardRequest: GetCardRequest): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/cards`, addCardRequest);
  }

  closeAccount(payload: GetCardRequest): Observable<GetCardResponse> {
    return this.http.get<GetCardResponse>(this.baseUrl + '/account/close', {
      params: payload,
    });
  }

  subscribeUser(): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + '/notifications/user');
  }
  getTransactionDetail(
    transactionId: string,
    transactionType: TransactionType,
  ): Observable<TransactionDetailResponse> {
    const url = `${this.baseUrl}/payments/${transactionType}/${transactionId}`;

    return this.http.get<TransactionDetailResponse>(url).pipe(
      catchError<any, Observable<boolean>>((_err) => {
        console.error(
          `Error getting with ${transactionType} transaction summary for transactionId ${transactionId}: ${JSON.stringify(
            _err,
          )}`,
        );
        throw _err;
      }),
    );
  }

  bankDocumentDownload(
    accountId: string,
    type: string,
    startDate: string,
    endDate: string,
    fileType = 'pdf',
  ): Observable<any> {
    const options = { responseType: 'blob' as 'json' };

    return this.http.get<any>(
      `${this.baseUrl}/account/${accountId}/statements/${fileType}?templateKey=${type}&from=${startDate}&to=${endDate}`,
      options,
    );
  }

  documentPaymentProofDownload(
    accountId: string,
    type: string,
    transactionId: string,
    transactionType: string,
    fileType = 'pdf',
  ): Observable<any> {
    const options = { responseType: 'blob' as 'json' };

    return this.http.get<any>(
      `${this.baseUrl}/account/${accountId}/transactions/${transactionId}/${fileType}?templateKey=${type}&transactionType=${transactionType}`,
      options,
    );
  }

  public getTransferReasons(accountId: string, country: string): Observable<TransferReason[]> {
    return this.http.get<TransferReason[]>(
      `${this.baseUrl}/payments/${accountId}/transfer-reasons/${country}`,
    );
  }

  bankDocumentDownloadUrl(
    accountId: string,
    type: string,
    startDate: string,
    endDate: string,
    fileType = 'pdf',
  ): string {
    return `${this.baseUrl}/account/${accountId}/statements/${fileType}?templateKey=${type}&from=${startDate}&to=${endDate}`;
  }

  documentPaymentProofDownloadUrl(
    accountId: string,
    type: string,
    transactionId: string,
    transactionType: string,
    fileType = 'pdf',
  ): string {
    return `${this.baseUrl}/account/${accountId}/transactions/${transactionId}/${fileType}?templateKey=${type}&transactionType=${transactionType}`;
  }

  documentExchangeProofDownload(
    type: string,
    exchangeId: string,
    transactionType: string,
    fileType = 'pdf',
  ): Observable<any> {
    const options = { responseType: 'blob' as 'json' };

    return this.http.get<any>(
      `${this.baseUrl}/account/exchanges/${exchangeId}/${fileType}?templateKey=${type}&transactionType=${transactionType}`,
      options,
    );
  }
  documentExchangeProofDownloadUrl(
    type: string,
    exchangeId: string,
    transactionType: string,
    fileType = 'pdf',
  ): string {
    return `${this.baseUrl}/account/exchanges/${exchangeId}/${fileType}?templateKey=${type}&transactionType=${transactionType}`;
  }

  updateBeneficiaryInfo(beneficiaryInfo: UpdateBeneficiaryRequest): Observable<GetBeneficiaryResponse> {
    return this.http.patch<GetBeneficiaryResponse>(
      `${this.baseUrl}/beneficiaries/${beneficiaryInfo.id}`,
      beneficiaryInfo,
    );
  }

  getPaymentRequestDetailsById(requestId: string): Observable<PaymentRequestSummary> {
    return this.http.get<PaymentRequestSummary>(`${this.baseUrl}/payments/request/${requestId}`);
  }
  proceedUploadFile(payload: uploadFileRequest): Observable<UploadFileResponse> {
    return this.http.post<UploadFileResponse>(`${this.baseUrl}/bulk-payments`, payload).pipe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      catchError<any, Observable<boolean>>((_err, _caught) => {
        console.error(`Error While Uploading CSV File: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  getBulkPaymentHistory(
    page: number,
    size: number,
    searchValue: string,
  ): Observable<BulkPaymentHistoryResponse> {
    return this.http.get<BulkPaymentHistoryResponse>(
      `${this.baseUrl}/bulk-payments?page=${page}&size=${size}&query=${searchValue}`,
    );
  }
  getBulkPaymentById(
    page: number,
    size: number,
    masterId: string,
    searchValue: string,
  ): Observable<PaginatedResponse<BulkPaymentResultList>> {
    return this.http.get<PaginatedResponse<BulkPaymentResultList>>(
      `${this.baseUrl}/bulk-payments/${masterId}?page=${page}&size=${size}&metadataInclude=summary&query=${searchValue}`,
    );
  }
  addBulkPaymentBeneficiary(masterId: string, benShowInList: boolean): Observable<UploadFileResponse> {
    return this.http
      .post<UploadFileResponse>(`${this.baseUrl}/bulk-payments/${masterId}/add-beneficiary`, {
        showInBeneficiaryList: benShowInList,
      })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        catchError<any, Observable<boolean>>((_err, _caught) => {
          console.error(`Add beneficiary: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }
  deleteTransaction(masterId: string, id: string): Observable<UploadFileResponse> {
    return this.http
      .delete<UploadFileResponse>(`${this.baseUrl}/bulk-payments/${masterId}/transactions/${id}`, {})
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        catchError<any, Observable<boolean>>((_err, _caught) => {
          console.error(`deleteTransaction: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  bulkPaymentContinueAnywayAndWithSuggestion(
    masterId: string,
    transactionId: string,
    request: { suggestion: boolean; name?: string },
  ) {
    return this.http
      .patch(
        `${this.baseUrl}//bulk-payments/${masterId}/transactions/${transactionId}/check-suggestion`,
        request,
      )
      .pipe(
        catchError<any, Observable<boolean>>((_err, _) => {
          console.error(`bulkPaymentContinueAnywayAndWithSuggestion: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  makeBulkPayment(id: string): Observable<TransferMoneyResponse> {
    return this.http
      .post<TransferMoneyResponse>(`${this.baseUrl}/bulk-payments/${id}/transfer-payments`, {})
      .pipe(
        catchError<any, Observable<boolean>>((_err, _caught) => {
          console.error(`Failed to process payment ${JSON.stringify(id)}: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  retryBulkPaymentTransfer(masterId: string, id: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/bulk-payments/${masterId}/transfer-payments/${id}/retry`, '');
  }

  cancelAllPayment(masterId: string): Observable<UploadFileResponse> {
    return this.http
      .post<UploadFileResponse>(`${this.baseUrl}/bulk-payments/${masterId}/transfer-payments/cancelled`, {})
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        catchError<any, Observable<boolean>>((_err, _caught) => {
          console.error(`All payment cancel: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  public getPaymentRequest(
    page: number,
    size: number,
    status?: string,
    searchValue?: string,
  ): Observable<PaginatedResponse<PaymentRequestListResponse>> {
    let params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    if (status) {
      params = params.set('status', status);
    }
    if (searchValue) {
      params = params.set('query', searchValue);
    }
    return this.http.get<PaginatedResponse<PaymentRequestListResponse>>(`${this.baseUrl}/payments/requests`, {
      params,
    });
  }

  public generatePaymentRequest(payload: PaymentRequestPayload): Observable<PaymentRequestPayload> {
    return this.http.patch<PaymentRequestPayload>(`${this.baseUrl}/payments/requests`, payload).pipe(
      catchError((error) => {
        console.error('Error generating payment request', error);
        throw error;
      }),
    );
  }

  public getPaymentSummaryCounters(queryParams: string[]): Observable<PaymentRequestSummaryCounts> {
    let params = new HttpParams();
    queryParams.forEach((param) => {
      params = params.append(`include${param.charAt(0).toUpperCase() + param.slice(1)}`, 'true');
    });
    return this.http
      .get<PaymentRequestSummaryCounts>(`${this.baseUrl}/summary/counters`, {
        params,
      })
      .pipe(
        catchError((error) => {
          console.error('Error getting payment summaries', error);
          throw error;
        }),
      );
  }
}

export class AccountRepositorySearchParams {
  public page?: number = 1;
  public size?: number = 10;
  public id?: string[];

  public name?: string;
  public currency?: string[];
  public country?: string[];
  public status?: AccountStatusType[] = [
    AccountStatusType.inactive,
    AccountStatusType.closed,
    AccountStatusType.declined,
  ];
}
