export enum PaymentsErrorCodes {
  REQUEST_ORG_MISMATCH = 'payment_request_org_mismatch',
  INITIATOR_REQUEST_ERROR = 'initiator_request_error',
  PAYMENT_REQUEST_FORBIDDDEN = 'payment_request_forbidden',
  PAYMENT_APPROVER_FORBIDDDEN = 'payment_approver_forbidden',
  PAYMENT_INITIATOR_FORBIDDDEN = 'payment_initiator_forbidden',
  PAYMENT_REQUEST_ALREADY_PROCCESSED = 'payment_request_already_proccessed',
  PAYMENT_OWN_REQUEST_ERROR = 'payment_own_request_error',
  PAYMENT_INVALID_ROLE_ERROR = 'payment_invalid_role_error',
  ALL_PAYMENT_REQUESTS_FAILED = 'all_payment_requests_failed',
  PAYMENT_RECORD_NOT_FOUND = 'payment_record_not_found',
  PAYMENT_SUMMARY_NOT_FOUND = 'payment_summary_not_found',
  INSUFFICIENT_BALANCE = 'insufficient_balance',
  TARGET2_BIC_AND_ADDRESS_REQUIRED = 'target2bic_and_address_required',
  EXCEEDED_TRANSFER_LIMIT = 'exceeded_transfer_limit',
  PAYMENT_REQUEST_FAILED = 'payment_request_failed',
  INVALID_AMOUNT = 'invalid_amount',
  AMOUNT_TOO_LOW = 'amount_too_low',
  PAYMENT_FAILED_BIC_REQUIRED = 'payment_failed_bic_required',
  PAYMENT_FAILED_ADDRESS_REQUIRED = 'payment_failed_address_required',
}
