/* eslint-disable @typescript-eslint/no-explicit-any */
import { BulkPaymentMasterStatus } from '@finxone-platform/form-action';
import { ClientType } from './account-type.enum';
import { SumsubReviewStatus } from './common.enum';
import { UiZoneWidgetConfig } from './ui-system-config.v2.type';

export type BulkPaymentsSummary = {
  totalPayments?: number;
  totalDuplicatePayments?: number;
  totalFailedPayments?: number;
  totalSuccessPayments?: number;
  totalSchedulePayments?: number;
  totalUniqueBeneficiaries?: number;
  totalPendingPayments?: number;
  totalPendingBeneficiary?: number;
  totalPendingTransactions?: number;
  totalAmount?: number;
  currency?: string;
  accountName?: string;
  accountId?: string;
  masterStatus: BulkPaymentMasterStatus;
  executedAt?: Date;
  totalErrors?: number;
};

export type PaginatedResponse<Type> = {
  result: Type[];
  totalPages: number | undefined;
  page: number;
  limit: number;
  totalItems?: number;
  meta?: {
    bulkPaymentsSummary?: BulkPaymentsSummary;
  };
};

export type ActiveOrgRole = {
  organisation: string;
  role: string;
};

export type OrgRoles = {
  id: string;
  name: string;
};

export type OrgTypes = {
  id: string;
  name: string;
  displayName: string;
  url: string;
  realm: string;
  domains: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attributes: any;
};

export type OrganisationAddressDetails = {
  street: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
};
export type OrgInfoDetails = {
  id: string;
  name: string;
  address: OrganisationAddressDetails;
  identificationNumber: string;
  incorporationDate: string;
};

export interface Address {
  address1: string;
  address2?: string;
  address3?: string;
  address4?: string;
  city: string;
  county?: string;
  state?: string;
  postalCode: string;
  country: string;
  flatName?: string;
  propertyName?: string;
  propertyNumber?: string;
}

export interface UserProfileData extends Address {
  id?: string;
  role?: string;
  organisation?: string;
  orgList?: Array<OrganisationList>;
  firstName: string;
  lastName: string;
  middleName?: string;
  fullName?: string;
  logo?: string;
  email: string;
  emailVerified?: boolean;
  dob: string;
  phoneNumber: string;
  phoneVerified?: boolean | string;
  phoneCode?: string;
  custom_questions?: customQuestions;
  userName?: string;
  extraAttributes?: {
    [key: string]: string;
  };
}

export type KybCompanyInfo = {
  companyName: string;
  country: string;
  identificationNumber: string;
  incorporationDate: string;
};

export const defaultUserProfileData = {
  firstName: '',
  lastName: '',
  middleName: '',
  email: '',
  dob: '06/01/2000',
  country: '',
  phoneNumber: '',
  address1: '',
  address2: '',
  address3: '',
  address4: '',
  city: '',
  county: '',
  postalCode: '',
  phoneCode: '',
} as UserProfileData;

export type FileUploadResponse = {
  message: string;
  path: string;
  success: boolean;
  id: string;
  filename: string;
};

export enum FileGlobalStatusFlag {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum ProviderClientType {
  CORPORATE = 'corporate',
  PERSONAL = 'personal',
}

export enum OnboardingConfigFlowTypes {
  BUSINESS = 'business-first',
  PERSONAL = 'personal-first',
}

export enum OrgCheckTypes {
  NAME = 'name',
  identificationNumber = 'identification_number',
}

export type FilesListResponse = {
  id: string;
  path: string;
  filename: string;
  global_status: FileGlobalStatusFlag;
  created_at: Date;
  category: string;
};

export type customQuestions = {
  [key: string]: string;
};

export type OrganisationList = {
  id: string;
  roles: Array<string>;
  name: string;
  kybVerificationStatus?: KybStatus;
  createdDate: string;
};

export type OrganisationDetail = {
  id: string;
  kybVerificationStatus: string;
};

export interface UserInfoType extends Address {
  id?: string;
  role?: string;
  organisation?: string;
  orgList?: Array<OrganisationList>;
  orgCounter?: number;
  fullName?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  logo?: string;
  email: string;
  emailVerified?: boolean;
  userName?: string;
  dob: string;
  country: string;
  phoneNumber: string;
  phoneVerified?: boolean | string;
  phoneCode?: string;
  custom_questions?: customQuestions;
  kycVerificationStatus?: string;
  profileCompletionStatus?: string;
  organisationKybStatus?: string;
  settings?: string;
  extraAttributes?: {
    [key: string]: string;
  };
  permissions?: string[];
}

export type OnboardUserResponse = {
  id: string;
  persona: string;
};

export type ResponseWithAlertCodesType<T> = {
  data: T;
  alertCodes?: string[];
};

export const customQuestionPrefix = 'question_name_';

export enum RequiredActions {
  NO_ACTION = '',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
}

export enum KycStatus {
  STARTED = 'started',
  SUCCESS = 'success',
  FAILED = 'failed',
  PENDING = 'pending',
  IN_PROGRESS = 'in-progress',
  KYC_PENDING = 'kyc-pending',
  NOT_STARTED = 'not-started',
}

export enum KybStatus {
  STARTED = 'started',
  SUCCESS = 'success',
  FAILED = 'failed',
  PENDING = 'pending',
  IN_PROGRESS = 'in-progress',
  KYB_PENDING = 'kyb-pending',
  REVIEW_PENDING = 'review-pending',
}

export enum ButtonActionType {
  KYC_EMAIL_PHONE_VERIFIED_CHECK = 'check-email-phone-verified',
  UPDATE_ORGANISATION = 'update-organisation',
  INVITE_GUEST = 'invite-guest-to-event',
  REMOVE_GUEST_FROM_EVENT = 'remove-guest-from-event',
  MANAGE_USERS_SAVE_PROGRESS = 'manage-users_save_progress',
  ADD_EMPLOYEE_CONFIRM_SAVE = 'add-employee-confirm-save',
  ADD_EMPLOYEE_INVITE = 'add-employee-invite',
  ASSIGN_PERSONA_FORM_STATE_CLEAR_AND_REDIRECT = 'clear-state-and-redirect',
  GO_TO_INVITE_GUEST = 'go-to-invite-guest',
  ASSIGN_PERSONA = 'assign_persona',
  ADD_USER_SEND_INVITE = 'add-user-send-invite',
  MANAGE_USER_EDIT_AND_SAVE = 'manage-user-edit-and-save',
  PAY_NEW_INTL_BENEFICIARY = 'pay-new-intl-beneficiary',
  ADD_EMPLOYEE_TO_TRIP_CONFIRM = 'add_employee_to_trip_confirm',
  REMOVE_INTL_BENEFICIARY = 'remove-intl-beneficiary',
  REMOVE_BENEFICIARY = 'remove-beneficiary',
  REDIRECT_TO_EDIT_BENEFICIARY = 'redirect-to-edit-beneficiary',
  SUBMIT_EDIT_BENEFICIARY = 'submit-edit-beneficiary',
  EDIT_BENEFICIARY = 'edit-beneficiary',
  EMAIL_ALL_GUESTS = 'email-all-guests',
  GO_TO_EDIT_EVENT = 'go-to-edit-event',
  DOWNLOAD_CSV = 'download-csv',
  PAYMENT_REQUEST = 'payment-request',
  PAYMENT_REQUEST_PROCESSING = 'payment-request-processing',
  PAYMENT_REQUEST_APPROVE = 'payment-request-approve',
  PAYMENT_REQUEST_REJECT = 'payment-request-reject',
  DOWNLOAD_CSV_BANK_STATEMENT = 'download_csv_bank_statement',
  DOWNLOAD_PDF_BANK_STATEMENT = 'download_pdf_bank_statement',
  PAYMENT_APPROVER_MAKE_PAYMENT = 'payment-approver-init-payment',
  BULK_PAYMENT_DOWNLOAD_UPLOAD_PROCESSING = 'payment-payment-download-upload-processing',
  REFRESH_BULK_PAYMENT_LIST = 'refresh-bulk-payment-list',
  BULK_PAYMENT_ADD_BENEFICIARY = 'bulk-payment-add-beneficiary',
  BULK_PAYMENT_BENEFICIARY_VALIDATION = 'bulk-payment-beneficiary-validation',
  BULK_PAYMENT_MAKE_PAYMENT = 'bulk-payment-make-payment',
  REVIEW_INFORMATION_VERIFICATION_PERSONAL = 'review-information-verification-personal',
  REVIEW_INFORMATION_VERIFICATION_BUSINESS = 'review-information-verification-business',
  CHECK_SMS_AUTH_STATUS_AND_REDIRECT = 'check-sms-auth-status-and-redirect',
  INIT_INTL_PAYMENT = 'init-intl-payment',
  TRIP_START_CONTINUE = 'trip-start-continue',
  TRIP_END_CONTINUE = 'trip-end-continue',
  TRIP_EXIT_POINT_CONTINUE = 'trip-exit-point-continue',
  TRIP_COUNTRY_CONTINUE = 'trip-country-continue',
  TRIP_UPLOAD_PASS = 'trip-upload-pass',
  TRIP_LEAVING_COUNTRY_CONTINUE = 'trip-leaving-country-continue',
  ADD_EDIT_FEE = 'add-edit-fee',
  ADD_EDIT_FEE_GROUP = 'add-edit-fee-group',
  ADD_REVENIR_NEW_TRIP = 'add-revenir-new-trip',
  MULTIPLE_COUNTRY_NOTI = 'multiple-country-noti',
  REMOVE_FEE_GROUP = 'remove-fee-group',
  ADD_TRANSACTION_STEP_1 = 'add-transaction-step-1',
  REMOVE_FEE = 'remove-fee',
  UPDATE_CLIENT_FEE_GROUP = 'update-client-fee-group',
  SUBMIT_PROFILE_CHANGES_IN_VERIFICATION_FLOW = 'submit-profile-changes-in-verification-flow',
  CREATE_AD_HOC_FEE = 'create-ad-hoc-fee',
  CLEAR_FORM_STATE_AND_REDIRECT_TO_FEE_GROUPS_LISTING = 'redirect-to-fee-groups-page',
  CLEAR_FORM_STATE_AND_REDIRECT_TO_EDIT_PROFILE = 'clear-form-state-and-redirect-to-edit-profile-page',
  REDIRECT_BUSINESS_USER_TO_LOGIN = 'redirect-business-user-to-login',
  REMOVE_CLIENT_FROM_FEE_GROUP = 'remove-client-from-fee-group',
  VERIFICATION_EDIT_INFORMATION = 'verification-edit-information',
  SUBMIT_PROFILE_CHANGES_IN_PROFILE_ZONE = 'submit-profile-changes-in-profile-zone',
  BULK_PAYMENT_AUTHENTICATION_LINK = 'bulk-payment-authentication-link',
  BULK_PAYMENT_CANCEL = 'bulk-payment-cancel',
  ADD_INTERNATIONAL_BENEFICIARY = 'add_international_beneficiary',
  BULK_PAYMENT_REJECT = 'bulk-payment-reject',
  BULK_PAYMENT_APPROVER = 'bulk-payment-approver',
  CONFIRM_ARCHIVE_TRIP = 'confirm-archive-trip',
  BARCODE_CONFIRM_CTA = 'barcode-confirm-cta',
  BARCODE_CONFIRM_SCAN_ARCHIVE_CTA = 'barcode-confirm-scan-archive-cta',
  DOWNLOAD_TAX_FREE_FORMS_CTA = 'download-tax-free-forms-cta',
  REVERT_FEE = 'revert-fee',
  RETRY_FEE = 'retry-fee',
  SECURE_CVV_DETAILS = 'secure-cvv-details',
  REVENIR_UPLOAD_REVIEWED_TRANSACTION_DOCUMENT = 'upload-reviewed-transaction-document',
  REVENIR_TRIP_BARCODE_SCAN_CHECK = 'revenir-trip-barcode-scan-check',
  REVENIR_TRIP_BARCODE_CONFIRMATION_DECLARATION = 'revenir-trip-barcode-confirmation-declaration',
  REVENIR_PREVIEW_BOARDING_PASS = 'revenir-preview-boarding-pass',
  SKIP_BOARDING_PASS_DETAILS = 'skip-boarding-pass-details',
  SKIP_PASSPORT_DETAILS = 'skip-passport-details',
  ADD_BENEFICIARY = 'add-beneficiary',
  PASSPORT_DETAILS = 'passport-details',
  USE_SUGGESTED_NAME_COP = 'use-suggested-name-cop',
  SHOW_PIN = 'show-pin',
  CANCEL_CARD = 'cancel-card',
  SET_CARD_NAME = 'set-card-name',
  REFRESH_CARDS_AND_REDIRECT = 'refresh-cards-and-redirect',
  GET_SENSITIVE_CARD_DETAILS = 'get-sensitive-card-details',
  SEPA_TRANSFER_PAYMENT_FLOW = 'sepa-transfer-payment-flow',
  SKIP_BENEFICIARY_ADDRESS = 'skip-beneficiary-address',
}

export enum ProfileCompletionStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
}

export type UserAttributes = {
  id: string;
  organisationId: string;
  role: string;
  extra_attributes: any;
};

export type UsersList = {
  limit: number;
  page: number;
  result: UsersResultList[];
  totalPages: number;
  totalItems: number;
  totalRecords: number;
};
export type AssignPersona = {
  email: string;
  persona: string;
  roles: string[];
};
export interface AssociateUserPersonaResponse {
  userExist: boolean;
  userId: string;
  persona: string;
}

export type UsersResultList = {
  id: string;
  organisationId: string;
  role: string;
  extra_attributes: any;
  kycVerificationStatus?: KycStatus;
  profileCompletionStatus?: ProfileCompletionStatus;
  activeOrganisationKybStatus?: string;
  orgCounter?: number;
  logo?: string;
  events?: string;
  email?: string;
  guest_type?: string;
  isPrimary?: string;
};

export type UsersAttribute = {
  dob: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phoneNumber: string;
  projectId: string;
  roleId: string;
  userName: string;
  country_code?: string;
  country?: string;
};

export type SearchApiParams = {
  page: number;
  size: number;
  query: string;
  sortKey: string;
};

export interface AutoSuggestAddressResponse {
  address: string;
  id: string;
}

export type AutoSuggestAddressResponseItems = Array<AutoSuggestAddressResponse>;

export type Roles = {
  name: string;
  slug: string;
};

export type CountryOption = {
  name: string;
  dial_code: string;
  code: string;
};

export type ImportUsers = {
  address: string;
  phone: string;
  email: string;
  last_name: string;
  first_name: string;
};

// TODO: remove duplicate address properties
export interface UserPayload extends Address {
  dob?: string;
  email: string;
  firstName: string;
  lastName: string;
  password?: string;
  phoneNumber: string;
  projectId?: string;
  userName?: string;
  street_adress?: string;
  post_code?: string;
  middleName?: string;
  extraAttributes?: any;
  roleId?: string;
  profileStatus?: boolean;
  phoneVerified?: string | boolean;

  //keys from Add User Flow
  organisationId?: string;
  profilePicture?: string;
  additionalRoles?: string;
  importUser?: boolean;
  emailVerified?: string | boolean;
}

export type ImportUsersPayload = {
  size: number;
  page: number;
  last_name: string;
  first_name: string;
};

export type LogosTypeList = {
  limit: number;
  page: number;
  totalPages: number;
  result: LogosResultTypeList[];
};

export type LogosResultTypeList = {
  filename: string;
  path: string;
  id: string;
  global_status: boolean;
  created_at?: Date;
  category?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  isSVGFile?: boolean;
};

export type LogodeletePayload = {
  id: string[];
  projectId: string;
};

export interface ProfileStateModel extends Address {
  id?: string;
  roleId?: string;
  orgId?: string;
  orgList?: Array<OrganisationList>;
  fullName: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  logo: string;
  email: string;
  emailVerified?: boolean;
  userName: string;
  dob: string;
  phoneNumber: string;
  phoneVerified?: boolean;
  phoneCode?: string;
  custom_questions?: customQuestions;
  activeOrganisationKybStatus?: string;
  activeOrganisationId?: string;
  activeRole?: string;
  settings?: string;
  kycVerificationStatus: string;
  kybVerificationStatus?: string;
  profileCompletionStatus: string;
  orgCounter?: number;
}

export interface EventGuestStateModel extends Partial<ProfileStateModel> {
  extraAttributes?: { [key: string]: string };
}

export abstract class ProviderClass<T> {
  widgetConfig: UiZoneWidgetConfig;
  attri: T;
  uniqueId: number;
  parentComponent?: string;
  allwidgets?: [];
  userProfileData?: any;
  userProfile: ProfileStateModel;
  id?: string;
}

export type SearchUsersPayload = {
  email: string;
  last_name: string;
  first_name: string;
};

export interface IAutoTokenResponse {
  token: string;
}

export type KycTokenResponse = {
  token: string;
  userId: string;
  email: string;
  phoneNumber: string;
};

export type KycCheckStatus = { status: SumsubReviewStatus };

export type GenerateOtpPayload = {
  fieldType: 'email' | 'phone';
  fieldValue: {
    [key: string]: string;
  };
  verificationCode?: string;
};

export type VerificationFlag = {
  fieldName: string;
  isVerified: boolean;
};

export interface ProgressBarWidgetModel {
  uniqueId?: string | number;
}

export type AllTransactionType = TransactionType | CurrencyExchangeTransactionType;

export enum TransactionType {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
  ALL = 'all',
}

export enum TransactionCategory {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export enum CurrencyExchangeTransactionType {
  CURRENCYINCOMING = 'currencyIncoming',
  CURRENCYOUTGOING = 'currencyOutgoing',
  CURRENCYALL = 'currencyAll',
}

export enum CardTransactionType {
  CARD_TRANSACTION_ALL = 'cardTransactionAll',
}

export interface OrganisationOnboardingRequest extends Address {
  name: string;
  userId?: string;
  extraAttributes: any;
}

export type CustomQuestionRequest = {
  name: string;
  type: string;
  label: string;
  value: unknown;
};

export type KeycloakUserInfo = {
  attributes: any;
  createdTimestamp: any;
  email: string;
  emailVerified: boolean;
  enabled: boolean;
  firstName: string;
  groups: string[];
  id: string;
  lastName: string;
  username: string;
  actionEnabled?: boolean;
};

export type KeycloakUser = {
  id: string;
  createdTimestamp: number;
  username: string;
  enabled: boolean;
  totp: boolean;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  email: string;
  attributes: {
    [key: string]: [string];
  };
  disableableCredentialTypes: string[];
  requiredActions: string[];
  notBefore: number;
};

export type NotificationChannelSetting = {
  sms: boolean;
  email: boolean;
  push: boolean;
  pushNative: boolean;
};

export type NotificationSettings = {
  dailyBalance: NotificationChannelSetting;
  transactionAlerts: NotificationChannelSetting;
  addBeneficiaryAlerts: NotificationChannelSetting;
  paymentsRequestAlerts: NotificationChannelSetting;
};
export interface NotificationSettingsPayload {
  dailyBalance: NotificationChannelSetting;
  transactionAlerts: NotificationChannelSetting;
  addBeneficiaryAlerts: NotificationChannelSetting;
  paymentsRequestAlerts: NotificationChannelSetting;
}

export type AddCustomQuestionAnswerRequest = {
  customQuestionUniqueId: string;
  value: string;
  checkboxValues?: string[];
};

export type ClientAddress = {
  street: string;
  city: string;
  state?: string;
  postalCode: string;
  country: string;
};

export type CreateClientRequest = {
  client?: {
    legalName: string;
    country: string;
    identificationNumber: string;
    incorporationDate?: string;
  };
  profile: {
    phoneNumber: string;
    email: string;
    dob?: string;
    country: string;
    residentialAddress?: ClientAddress;
    firstName: string;
    lastName: string;
  };
  address: ClientAddress;
  organisationId: string;
  clientType: ProviderClientType;
  metadata?: any;
};

export type CreateClientResponse = {
  id: string;
};

export type ExistedClientResponse = {
  id: string;
  clientExist: boolean;
  createdCustomers: string[];
};

export type VerificationOrganisationData = {
  id: string;
  name: string;
  address: {
    country: string;
    state: string;
    city: string;
    street: string;
    postalCode: string;
  };
  identificationNumber: string;
  incorporationDate: string;
};

export interface OrganisationVerificationRequest extends Address {
  name: string;
  extraAttributes: any;
}

export type OrgUserInfo = {
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  city: string;
  dob: string;
  emailVerified?: boolean;
  phoneVerified?: boolean;
  position?: string;
  persona?: string;
};

export type OrgUserPaginateResponse = {
  result: OrgUserInfo[];
  totalPages: number;
  totalRecords: number;
  page: number;
  limit: number;
};

//TODO: update to extend Address interface
export interface AddEmployeeRequestType {
  email: string;
  emailVerified?: boolean;
  phoneNumber: string;
  phoneVerified?: boolean;
  country?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  flatName?: string;
  propertyName?: string;
  propertyNumber?: string;
  city?: string;
  county?: string;
  postalCode?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  userName: string;
  roleId?: string;
  dob?: string;
  password: string;
  organisationId: string;
  external?: boolean;
  importUser?: boolean;
  additionalRoles?: string[];
  profilePicture?: string;
  logo?: string;
  extraAttributes?: { [key: string]: string };
}

export interface InviteEmployeeType {
  email: string;
  orgId: string;
  persona: string;
  roles: string[];
}

export type ZoneActionOptions = {
  label: string;
  value: string;
  isIdNotExist: string;
};

export type FileType = {
  fileType: string;
  filename: string;
  id: string;
  path: string;
};

export type InviteGuestRequestType = {
  email: string;
  name: string;
  type: string;
  typeId: string;
};

export type InviteGuestResponseType = {
  userExist: string;
  userId: string;
};

export type EmployeeTripType = {
  id: string;
  user_id: string;
  trip_id?: string;
  trip: {
    start: string;
    name: string;
    id: string;
    end: string;
    embarkLocation?: string | null;
    disembarkLocation?: string | null;
    embarkLocation_2?: string | null;
    disembarkLocation_2?: string | null;
    facility?: {
      attributes?: string;
      id: string;
      is_mobile?: boolean;
      name: string;
    };
  };
};

export type ViewUserAssignPersona = {
  id: string;
  roleId: string;
  additionalRoles: string[];
};

export type BulkPaymentResultList = {
  id: string;
  row?: string;
  name: string;
  beneficiaryId: string | null;
  beneficiaryStatus: string;
  beneficiaryExistStatus: string;
  amount: string;
  currency: string;
  scheduleType: string;
  scheduleDate: string;
  transactionId: string | null;
  transactionReference: string | null;
  transactionPaymentReason: string | null;
  transactionStatus: string;
  status: string;
  created_at: string;
  executed_at: string;
  updated_at: string;
  beneficiaryBankAccounts: [
    {
      country: string;
      currency: string;
      account_number: string;
      sort_code: string;
      iban: string | null;
      bic: string | null;
      aba: string | null;
      ach: string | null;
      rtn_canada: string | null;
      clabe: string | null;
      bsb_code: string | null;
      ifsc: string | null;
      cnaps: string | null;
      bank_code: string | null;
      branch_code: string | null;
      created_at: string;
      updated_at: string;
      id: string;
      address: {
        address_line_1: string | null;
        address_line_2: string | null;
        address_line_3: string | null;
        city: string | null;
        region: string;
        postal_code: string;
        country: string;
        house_number: string | null;
        created_at: string;
        updated_at: string;
        id: string;
      };
    },
  ];
  isDuplicate?: boolean;
  isError?: boolean;
  entityStyle?: { 'background-color': string } | '';
  actionEnabled?: boolean;
  beneficiaryCoPStatus?: string;
  beneficiaryCoPCode?: string;
  beneficiaryExistAndCopStatus?: string;
  type?: string;
  beneficiaryCoPName?: string;
};

export type InternalOnboard = {
  type: ClientType;
  payload: InternalOnboardingIndividual | InternalOnboardingCorporate;
};

export type InternalOnboardingIndividual = {
  firstName: string;
  middleName?: string;
  lastName: string;
  country: string;
  kycStatus: KycStatus;
  rolesToAssign: string[];
  address: {
    address1: string;
    address2?: string;
    address3?: string;
    address4?: string;
    city: string;
    county?: string;
    country: string;
    postalCode: string;
  };
  phoneNumber: string;
  dob: string;
  email: string;
  userName?: string;
  attributes?: {
    [key: string]: string;
  };
};

export type InternalOnboardingCorporate = {
  legalName: string;
  incorporationDate: Date;
  country: string;
  kybStatus: KybStatus;
  address: {
    address1: string;
    address2?: string;
    address3?: string;
    address4?: string;
    city: string;
    county?: string;
    country: string;
    postalCode: string;
  };
  attributes?: {
    [key: string]: string;
  };
  user: {
    rolesToAssign: string[];
    firstName: string;
    middleName?: string;
    lastName: string;
    address: {
      address1: string;
      address2?: string;
      address3?: string;
      address4?: string;
      city: string;
      county?: string;
      country: string;
      postalCode: string;
    };
    phoneNumber: string;
    dob: string;
    email: string;
    userName?: string;
  };
};
