import { Router } from '@angular/router';
import { OtpParam } from '@finxone-platform/form-action';
import { BaseWidgetProperties, PaymentFlowTypes } from '@finxone-platform/shared/sys-config-types';
import { redirectToPage } from '../cta-button-actions.utils';

export function skipAddressRedirection(router: Router, widgetProperties: BaseWidgetProperties) {
  const queryParams = new URLSearchParams(router.url?.split('?')[1] ?? '');
  const paymentFlow = queryParams.get('paymentflow');
  if (paymentFlow === PaymentFlowTypes.SEPA) {
    widgetProperties['urlToNavigate'] = `review-information-match?flow=${OtpParam.AddBeneficiary}`;
  } else {
    widgetProperties['urlToNavigate'] = `intl-review-beneficiary-details`;
  }
  redirectToPage(router, widgetProperties);
}
