<button
  [id]="id"
  [class]="class"
  [type]="type"
  [ngClass]="[
    isAllowedGlobalStyling &&
    ((leftIcon && leftIcon !== 'none' && leftIcon !== '') ||
      (rightIcon && rightIcon !== 'none' && rightIcon !== ''))
      ? 'with-icon'
      : '',
    isAllowedGlobalStyling ? borderRadiusClass : ''
  ]"
  [disabled]="disabled"
  [class.is-disabled]="disabled"
  (click)="onClick()"
  [ngStyle]="buttonStyle"
>
  <mat-icon
    *ngIf="leftIcon && leftIcon !== ''"
    [ngStyle]="!isAllowedGlobalStyling ? leftIconSize : {}"
    [svgIcon]="leftIcon"
    [ngClass]="!isAllowedGlobalStyling ? 'button-icon-svg' : 'button-icon'"
  ></mat-icon>
  <ng-container *ngIf="class.includes('btn-small'); else btnNormal">
    <small *ngIf="labeltext?.length" [innerHTML]="labeltext | safeHtml"></small>
  </ng-container>
  <ng-template #btnNormal>
    <div
      *ngIf="labeltext?.length"
      [class.buttonText]="isAllowedGlobalStyling"
      [innerHTML]="labeltext | safeHtml"
    ></div>
  </ng-template>
  <mat-icon
    *ngIf="rightIcon && rightIcon !== 'none' && rightIcon !== ''"
    [ngStyle]="!isAllowedGlobalStyling ? rightIconSize : {}"
    [svgIcon]="rightIcon"
    [ngClass]="!isAllowedGlobalStyling ? 'ml-auto button-icon-svg' : 'button-icon right-icon'"
    [class]="rightIconClass"
  ></mat-icon>
</button>
