<div
  id="alertMessageWrapper"
  class="alert-wrapper is-center"
  [ngStyle]="getAlertStyles()"
  [ngClass]="activeDevice === 'mobile' ? 'mobile-alert' : 'desktop-alert'"
  [class.success-alert]="alertData?.type === 'success'"
  [class.error-alert]="alertData?.type === 'error'"
  [class.info-alert]="alertData?.type === 'info'"
  [class.warning-alert]="alertData?.type === 'warning'"
  [class.close-on-hover]="alertData?.dismissalType === dismissalTypes.CLOSE_ON_HOVER"
  (mouseover)="checkDismissalTypeAndClose(alertData, 'alertMessageWrapper')"
  (touchstart)="handleSwipe($event, 'touchstart')"
  (touchmove)="handleSwipe($event, 'touchmove')"
  (touchend)="handleSwipe($event, 'touchend')"
>
  <div class="alert-message" [style.align-items]="alertData.headerMsg ? 'flex-start' : 'center'">
    @if (leftIcon) {
    <span class="icon-wrap alert-left-icon">
      <mat-icon
        alt="icon"
        class="error-succes-icon mr-1"
        [svgIcon]="leftIcon.name"
        [ngStyle]="{ color: leftIcon.color, width: leftIcon.size, height: leftIcon.size }"
      ></mat-icon>
    </span>
    }
    <div class="alert-msg-info">
      <div
        *ngIf="alertData?.headerMsg"
        [ngStyle]="alertTitleStyle"
        class="alert-header alert-title"
        [innerHTML]="renderedTitleContent | safeHtml"
      ></div>

      <div *ngIf="alertData?.msg" class="alert-body alert-description">
        <span [ngStyle]="alertDescriptionStyle" [innerHTML]="renderedDescriptionContent | safeHtml"></span>
      </div>
    </div>
    @if(rightIcon){
    <span class="close-icon icon-wrap d-flex alert-right-icon">
      <mat-icon
        [ngStyle]="{ color: rightIcon?.color, width: rightIcon?.size, height: rightIcon?.size }"
        (click)="close()"
        alt="icon"
        [svgIcon]="rightIcon.name"
      ></mat-icon>
    </span>
    }
  </div>
</div>
