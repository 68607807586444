<div class="pointer-events-none">
  <nav class="navbar footer-navbar" [style.padding-bottom]="bottomPadding" [ngStyle]="footerStyles">
    <div class="navbar-container justify-content-between is-center" [ngStyle]="containerStyles">
      <ng-container *ngFor="let item of navBarMobileConfig?.layout">
        <div class="navbar-item">
          <a class="is-center" (click)="navigateToZone(item.navigateToZone)">
            <mat-icon
              [ngStyle]="{
              'width': item.iconSizeWidth + item.iconSizeUnit,
              'height': item.iconSizeHeight + item.iconSizeUnit,
            }"
              [style.color]="
                currentUrl === item.navigateToZone ? item.activeIconColor : item.inactiveIconColor
              "
              class="nonactive"
              [svgIcon]="item.icon"
              [ngClass]="currentUrl === item.navigateToZone ? 'active' : 'nonactive'"
            ></mat-icon>
            <span
              [style.color]="item.inactiveIconColor"
              *ngIf="navBarMobileConfig.showLabels"
              [ngClass]="currentUrl === item.navigateToZone ? 'active' : 'nonactive'"
              [ngStyle]="{ 'font-size.px': navBarMobileConfig.labelFontSize }"
              >{{ item.title ? item.title : '&nbsp;' }}</span
            >
          </a>
        </div>
      </ng-container>
    </div>
  </nav>
</div>
