import {
  CommonPageConfig,
  SupportedDevice,
  UiPageConfig,
  UiPagePageConfiguration,
} from '@finxone-platform/shared/sys-config-types';

export function getModalConfigStyle(pageConfig: UiPageConfig, activeDevice: SupportedDevice) {
  const pageConfiguration = onSetDefaultModalValue<UiPagePageConfiguration>(
    pageConfig?.pageConfiguration as UiPagePageConfiguration,
    activeDevice,
  );

  const modalConfiguration = pageConfiguration?.modalConfiguration;

  const modalHeightAndWidth = modalConfiguration?.cssEnabled
    ? {}
    : {
        height: `${modalConfiguration?.modalSizeHeight}${modalConfiguration?.modalSizeMarginUnit}`,
        width: `${modalConfiguration?.modalSizeWidth}${modalConfiguration?.modalSizeMarginUnit}`,
      };

  const style = {
    ...modalHeightAndWidth,
    backgroundColor: pageConfiguration?.backgroundColor ?? 'var(--color-background)',
    borderTopLeftRadius: `${pageConfiguration?.borderConfig?.borderRadius?.topLeft}${pageConfiguration?.borderConfig?.borderRadius?.unit}`,
    borderBottomLeftRadius: `${pageConfiguration?.borderConfig?.borderRadius?.bottomLeft}${pageConfiguration?.borderConfig?.borderRadius?.unit}`,
    borderTopRightRadius: `${pageConfiguration?.borderConfig?.borderRadius?.topRight}${pageConfiguration?.borderConfig?.borderRadius?.unit}`,
    borderBottomRightRadius: `${pageConfiguration?.borderConfig?.borderRadius?.bottomRight}${pageConfiguration?.borderConfig?.borderRadius?.unit}`,
    boxShadow: `${modalConfiguration?.shadowOffsetX}px ${modalConfiguration?.shadowOffsetY}px ${modalConfiguration?.shadowBlur}px ${modalConfiguration?.shadowSpread}px ${modalConfiguration?.shadowColour}`,
    border: pageConfiguration?.borderConfig?.width
      ? `${pageConfiguration?.borderConfig?.width}${pageConfiguration?.borderConfig?.unit} solid ${pageConfiguration?.borderConfig?.color}`
      : undefined,
  };
  const marginStyle = onGetModalConfigMargin(pageConfig);
  const paddingStyle = onGetModalConfigPadding(pageConfig);
  const closeIconContentStyle = onGetCloseIconContentStyle(pageConfig);

  return { contentStyle: style, marginStyle, paddingStyle, closeIconContentStyle };
}

const onGetModalConfigMargin = (pageConfig: UiPageConfig) => {
  const modalConfiguration = pageConfig.pageConfiguration?.modalConfiguration;
  const style = {
    marginTop: `${modalConfiguration?.marginTop}${modalConfiguration?.marginUnit}`,
    marginBottom: `${modalConfiguration?.marginBottom}${modalConfiguration?.marginUnit}`,
    marginLeft: `${modalConfiguration?.marginLeft}${modalConfiguration?.marginUnit}`,
    marginRight: `${modalConfiguration?.marginRight}${modalConfiguration?.marginUnit}`,
  };
  return style;
};

const onGetModalConfigPadding = (pageConfig: UiPageConfig) => {
  const modalConfiguration = pageConfig.pageConfiguration?.modalConfiguration;
  const style = {
    paddingTop: `${modalConfiguration?.paddingTop}${modalConfiguration?.paddingUnit}`,
    paddingBottom: `${modalConfiguration?.paddingBottom}${modalConfiguration?.paddingUnit}`,
    paddingLeft: `${modalConfiguration?.paddingLeft}${modalConfiguration?.paddingUnit}`,
    paddingRight: `${modalConfiguration?.paddingRight}${modalConfiguration?.paddingUnit}`,
  };
  return style;
};

const onGetCloseIconContentStyle = (pageConfig: UiPageConfig) => {
  const modalConfiguration = pageConfig.pageConfiguration?.modalConfiguration;
  const style = {
    top: getNumberValue(
      modalConfiguration?.closeIconTopPosition,
      modalConfiguration?.closeIconTopPositionUnit,
    ),
    bottom: getNumberValue(
      modalConfiguration?.closeIconBottomPosition,
      modalConfiguration?.closeIconBottomPositionUnit,
    ),
    left: getNumberValue(
      modalConfiguration?.closeIconLeftPosition,
      modalConfiguration?.closeIconLeftPositionUnit,
    ),
    right: getNumberValue(
      modalConfiguration?.closeIconRightPosition,
      modalConfiguration?.closeIconRightPositionUnit,
    ),
  };
  return style;
};

const getNumberValue = (closeIconProperty: number | undefined, unit: string | undefined) => {
  return Number(closeIconProperty) ? `${closeIconProperty}${unit}` : undefined;
};

export const onSetDefaultModalValue = <T extends UiPagePageConfiguration | CommonPageConfig>(
  pageConfiguration: T,
  activeDevice: SupportedDevice,
) => {
  if (!Number(pageConfiguration?.modalConfiguration?.modalSizeWidth)) {
    pageConfiguration = {
      ...pageConfiguration,
      modalConfiguration: {
        ...pageConfiguration?.modalConfiguration,
        modalSizeWidth: activeDevice === 'mobile' ? 400 : 600,
        modalSizeMarginUnit: 'px',
      },
    };
  }
  if (!Number(pageConfiguration?.modalConfiguration?.modalSizeHeight)) {
    pageConfiguration = {
      ...pageConfiguration,
      modalConfiguration: {
        ...pageConfiguration?.modalConfiguration,
        modalSizeHeight: activeDevice === 'mobile' ? 500 : 600,
        modalSizeMarginUnit: 'px',
      },
    };
  }

  if (!Number(pageConfiguration?.modalConfiguration?.closeIconSize)) {
    pageConfiguration = {
      ...pageConfiguration,
      modalConfiguration: {
        ...pageConfiguration?.modalConfiguration,
        closeIconSize: 32,
        closeIconUnit: 'px',
      },
    };
  }

  if (!pageConfiguration?.backgroundColor) {
    pageConfiguration = {
      ...pageConfiguration,
      backgroundColor: '#FFFFFF',
    };
  }
  if (!pageConfiguration?.borderConfig?.width) {
    pageConfiguration = {
      ...pageConfiguration,
      borderConfig: {
        ...pageConfiguration?.borderConfig,
        width: undefined,
        unit: undefined,
      },
    };
  }
  return pageConfiguration;
};

export const modifyLoggedInModalPageUrl = ({ zoneUrl, pageUrl }: { pageUrl: string; zoneUrl: string }) => {
  const isCurrentNavigationModalPage = location.pathname.includes('modal:');
  // Crete the base URL without any existing modals
  const parentZonePath = location.pathname.replace(/\(.*?\)/g, '')?.replace(/\/+$/, '');

  return `${parentZonePath}/(modal:modal/${zoneUrl}/${pageUrl})${
    isCurrentNavigationModalPage ? '?isModal=true' : '?isModal=false'
  }`;
};

export const modifyLoggedOutModalPageUrl = ({
  zoneUrl,
  pageUrl,
  url,
  role,
}: {
  pageUrl: string;
  zoneUrl: string;
  role: string;
  url: string;
}) => {
  const isCurrentNavigationModalPage = location.pathname.includes('modal:');
  // Crete the base URL without any existing modals
  const parentZonePath = url?.replace(/\(.*?\)/g, '')?.replace(/\/+$/, '');

  return `${parentZonePath}/(modal:modal/${zoneUrl}/${pageUrl}/${role}/default)${
    isCurrentNavigationModalPage ? '?isModal=true' : '?isModal=false'
  }`;
};
