import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PasswordValidationRules } from '../../Modules/shared/widgets/form-submitter/form-submitter.component';

export function createPasswordRulesTemplate(passwordValidationRules: PasswordValidationRules): {
  [key: string]: string;
} {
  const passwordRulesMessages: { [key: string]: string } = {};
  if (passwordValidationRules?.length) {
    passwordRulesMessages['minLength'] = `Min ${passwordValidationRules.length} characters`;
  }
  if (passwordValidationRules?.length) {
    passwordRulesMessages['maxLength'] = `max ${passwordValidationRules.maxLength} characters`;
  }
  if (passwordValidationRules?.upperCase) {
    passwordRulesMessages['upperCase'] = `${passwordValidationRules.upperCase} uppercase letter`;
  }
  if (passwordValidationRules?.lowerCase) {
    passwordRulesMessages['lowerCase'] = `${passwordValidationRules.lowerCase} lowercase letter`;
  }
  if (passwordValidationRules?.digits) {
    passwordRulesMessages['digits'] = `${passwordValidationRules.digits} number`;
  }
  if (passwordValidationRules?.specialChars) {
    passwordRulesMessages['specialChars'] = `${passwordValidationRules.specialChars} special character`;
  }
  return passwordRulesMessages;
}

export function createPasswordFieldValidator(rules: PasswordValidationRules): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const errors: ValidationErrors = {};

    if (rules?.upperCase) {
      const upperCaseChars = (value.match(/[A-Z]/g) || [])?.length;
      if (upperCaseChars < rules.upperCase) {
        errors['upperCase'] = {
          required: rules.upperCase,
          found: upperCaseChars,
        };
      }
    }

    if (rules?.lowerCase) {
      const lowerCaseChars = (value.match(/[a-z]/g) || [])?.length;
      if (lowerCaseChars < rules.lowerCase) {
        errors['lowerCase'] = {
          required: rules.lowerCase,
          found: lowerCaseChars,
        };
      }
    }

    if (rules?.specialChars) {
      const specialChars = (value.match(/[!@#$%^&*(),.?":{}|<>]/g) || [])?.length;
      if (specialChars < rules.specialChars) {
        errors['specialChars'] = {
          required: rules.specialChars,
          found: specialChars,
        };
      }
    }

    if (rules?.digits) {
      const digits = (value.match(/[0-9]/g) || [])?.length;
      if (digits < rules.digits) {
        errors['digits'] = {
          required: rules.digits,
          found: digits,
        };
      }
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };
}

export const passwordMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password: string = control.get('newPassword')?.value; // get password from our newPassword form control
  const confirmPassword: string = control.get('confirmPassword')?.value; // get password from confirmPassword form control
  return password === confirmPassword ? null : { noPassswordMatch: true };
};
