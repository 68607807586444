import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { IconCacheService } from '@finxone-platform/shared/services';
import {
  iconSetGoogle,
  iconSetMaterial,
  iconSetMaterialBold,
  iconSetMisc,
  iconSetPayabl,
  iconSetPrimeNg,
  iconSetRevenir,
  IconType,
} from '@finxone-platform/shared/sys-config-types';

export const registerCustomIcons = function (
  matIconRegistry: MatIconRegistry,
  sanitizer: DomSanitizer,
  iconCacheService?: IconCacheService,
) {
  // Register the icon URL with MatIconRegistry
  function registerIcon(iconName: string, filePath: string) {
    matIconRegistry.addSvgIcon(iconName, sanitizer.bypassSecurityTrustResourceUrl(filePath));
  }

  // Collect all icon sets
  const allIcons: IconType[] = [];
  allIcons.push(...iconSetPrimeNg);
  allIcons.push(...iconSetRevenir);
  allIcons.push(...iconSetMaterialBold);
  allIcons.push(...iconSetMaterial);
  allIcons.push(...iconSetMisc);
  allIcons.push(...iconSetPayabl);
  allIcons.push(...iconSetGoogle);

  // Register icons with MatIconRegistry
  allIcons.forEach((icon) => registerIcon(icon.name, icon.url));

  // Initialize the URL mappings in IconCacheService for lazy loading
  if (iconCacheService) {
    iconCacheService.initializeIconUrls(allIcons);
  }
};
