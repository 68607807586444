import { Injectable } from '@angular/core';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  NotificationAlertTypeEnum,
  NotificationSettings,
  ProfileStateModel,
  UserInfoType,
} from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap, throwError } from 'rxjs';
import { AddProgressBarStack, RemoveProgressBarStack } from '../actions/progress-bar.action';
import {
  GetProfile,
  NotificationSettingsUpdate,
  SetActiveOrgId,
  SetActiveRole,
  SetProfile,
  UserProfileFetched,
  UserProfileUpdated,
} from '../actions/user-profile.action';

import * as Sentry from '@sentry/angular-ivy';
import { MetadataService } from '../services/metadata-service/metadata-service.service';

@State<ProfileStateModel>({
  name: 'profile',
  defaults: {
    id: undefined,
    firstName: '',
    middleName: undefined,
    lastName: '',
    fullName: '',
    logo: '',
    email: '',
    emailVerified: false,
    dob: '',
    country: '',
    phoneNumber: '',
    phoneVerified: false,
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    city: '',
    county: '',
    postalCode: '',
    phoneCode: undefined,
    custom_questions: undefined,
    flatName: undefined,
    propertyName: undefined,
    propertyNumber: undefined,
    userName: '',
    activeOrganisationKybStatus: '',
    activeOrganisationId: undefined,
    activeRole: undefined,
    settings: '',
    profileCompletionStatus: 'pending',
    kycVerificationStatus: 'pending',
    orgCounter: 0,
  },
})
@Injectable()
export class ProfileState {
  defaultNotificationSettings: NotificationSettings = {
    [NotificationAlertTypeEnum.DAILY_BALANCE]: {
      sms: false,
      email: false,
      push: false,
      pushNative: true,
    },
    [NotificationAlertTypeEnum.TRANSACTION_ALERTS]: {
      sms: false,
      email: false,
      push: false,
      pushNative: true,
    },
    [NotificationAlertTypeEnum.ADD_BENEFICIARY_ALERTS]: {
      sms: false,
      email: false,
      push: false,
      pushNative: true,
    },
    [NotificationAlertTypeEnum.PAYMENTS_REQUEST_ALERTS]: {
      sms: false,
      email: false,
      push: false,
      pushNative: true,
    },
  };
  constructor(
    private metadataService: MetadataService,
    private alertHandlerService: AlertHandlerService,
    private store: Store,
  ) {}

  @Selector()
  static getProfile(state: ProfileStateModel) {
    return state;
  }
  @Selector()
  static getProfilePicture(state: ProfileStateModel) {
    return state.logo;
  }

  @Action(GetProfile)
  fetchProfile(ctx: StateContext<ProfileStateModel>, action: GetProfile) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetUser' }));
    try {
      return this.metadataService.getUser().pipe(
        tap((userProfileData: UserInfoType) => {
          const currentProfile = ctx.getState();

          if (currentProfile.id !== userProfileData.id || currentProfile.email !== userProfileData.email) {
            Sentry.setUser({
              id: userProfileData.id,
              email: userProfileData.email,
            });
          }

          ctx.setState({
            id: userProfileData.id,
            firstName: userProfileData.firstName,
            middleName: userProfileData.middleName,
            lastName: userProfileData.lastName,
            fullName: userProfileData.fullName as string,
            logo: userProfileData.logo as string,
            email: userProfileData.email,
            emailVerified: userProfileData.emailVerified,
            dob: userProfileData.dob,
            country: userProfileData.country,
            phoneNumber: userProfileData.phoneNumber,
            phoneVerified: userProfileData.phoneVerified as boolean,
            address1: userProfileData.address1,
            address2: userProfileData.address2,
            address3: userProfileData.address3,
            address4: userProfileData.address4,
            city: userProfileData.city,
            county: userProfileData.county,
            postalCode: userProfileData.postalCode,
            phoneCode: userProfileData.phoneCode,
            custom_questions: userProfileData.custom_questions,
            flatName: userProfileData.flatName,
            propertyName: userProfileData.propertyName,
            propertyNumber: userProfileData.propertyNumber,
            userName: userProfileData.userName as string,
            activeOrganisationKybStatus: userProfileData.organisationKybStatus,
            activeOrganisationId: userProfileData.organisation,
            activeRole: userProfileData.role,
            orgList: userProfileData.orgList,
            orgCounter: userProfileData.orgCounter,
            settings: JSON.stringify(
              userProfileData.settings !== '{}' ? userProfileData.settings : this.defaultNotificationSettings,
            ),
            kycVerificationStatus: userProfileData?.kycVerificationStatus ?? 'pending',
            profileCompletionStatus: userProfileData?.profileCompletionStatus ?? 'pending',
          });
          if (userProfileData.id) localStorage.setItem('lastLoggedInId', userProfileData.id);
          ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetUser' }));

          if (action.emitUserProfileFetched) ctx.dispatch(new UserProfileFetched());
        }),
      );
    } catch (err) {
      ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetUser' }));
      return throwError(() => err);
    }
  }

  @Action(SetActiveRole)
  setActiveRole(ctx: StateContext<ProfileStateModel>, action: SetActiveRole) {
    return ctx.patchState({
      activeRole: action.role,
    });
  }

  @Action(SetActiveOrgId)
  setActiveOrgId(ctx: StateContext<ProfileStateModel>, action: SetActiveOrgId) {
    // Set active org in local storage to persist choice and read later:

    localStorage.setItem('activeOrganisationId', action.organisationId);

    // this.store.dispatch(new GetOrganisation(action.organisationId));
    return ctx.patchState({
      activeOrganisationId: action.organisationId,
    });
  }

  @Action(SetProfile)
  fetchSetProfile(ctx: StateContext<ProfileStateModel>, action: SetProfile) {
    try {
      const state = ctx.getState();
      const userProfileData = action.payload;

      //in cases where dob isn't an option to change, its setting dob to NaN
      if (isNaN(Date.parse(userProfileData.dob))) {
        userProfileData.dob = state.dob;
      }
      const filteredData = Object.fromEntries(
        Object.entries(userProfileData).filter(([, value]) => value !== undefined),
      );
      //merge existing state with new form values
      const updatedState = Object.assign(state, filteredData);

      this.metadataService
        .updateProfile(updatedState)
        .pipe(
          tap((response) => {
            if (response) {
              this.alertHandlerService.showAlertFn('success', 'Your profile is successfully updated');
              ctx.dispatch(new UserProfileUpdated());
            }
          }),
        )
        .subscribe();
      return ctx.patchState(updatedState);
    } catch (err) {
      return throwError(() => err);
    }
  }

  @Action(NotificationSettingsUpdate)
  notificationSettingsUpdate(ctx: StateContext<UserInfoType>, action: NotificationSettingsUpdate) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      settings: JSON.stringify(action.payload),
    });
  }
}
