import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { Store } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { KeycloakService } from 'keycloak-angular';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { IS_EXTERNAL_KEYCLOAK } from './http-constants';
import { ProjectSettingsState } from './state/project-settings.state';
import { isAuthenticatedRoute } from './utils/auth-checks.utils';
import { clearLocalStorageExcept, findAndShowAlertFromCode } from './utils/utils';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private alertHandlerService: AlertHandlerService,
    protected keycloakService: KeycloakService,
    private store: Store,
  ) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.has('x-finxone-skip-interceptors')) {
      const modifiedRequest = request.clone({
        headers: request.headers.delete('x-finxone-skip-interceptors'),
      });
      return next.handle(modifiedRequest);
    }
    return next.handle(request).pipe(
      tap((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          this.handleSuccessResponse(event);
        }
      }),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(request, err);
      }),
    );
  }

  private handleSuccessResponse(successEvent: HttpResponse<unknown>) {
    if ((successEvent.body as any)?.alertCodes) {
      findAndShowAlertFromCode(this.store, this.alertHandlerService, (successEvent.body as any).alertCodes);
    }
  }

  handleError(request: HttpRequest<unknown>, error: HttpErrorResponse) {
    let errorMessage: any;
    let errorObj: any;
    const token = localStorage.getItem('token');
    /**
     * @description: checking if token is not there and user tries to login authenticated path then it wont go inside and it wont display the error which are coming.
     */
    if (!token && isAuthenticatedRoute(window.location.pathname)) {
      return this.throwErrorFunc(errorMessage, error);
    }
    if (this.isJSONString(error.error)) {
      errorObj = JSON.parse(error.error);
    } else {
      errorObj = error.error;
    }
    if (errorObj?.alertCodes?.length) {
      findAndShowAlertFromCode(this.store, this.alertHandlerService, errorObj.alertCodes);
      errorMessage = error.message;
    } else if (errorObj?.message?.length) {
      if (typeof errorObj.message == 'string') {
        errorMessage = errorObj.message;
      } else {
        errorMessage = errorObj.message[0];
      }

      if (error.status !== undefined && error.status !== 0) this.show(errorMessage);
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${
        errorObj?.message ? errorObj.message : 'Server Error'
      }`;

      const isExternalKeycloak: boolean = request.context.get(IS_EXTERNAL_KEYCLOAK);
      if (!isExternalKeycloak && error.status !== undefined && error.status !== 0) this.show(errorMessage);
    }

    if (error.status === 401) {
      this.sendToLogin();
    }

    return this.throwErrorFunc(errorMessage, error);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private throwErrorFunc(errorMessage: any, error: HttpErrorResponse) {
    return throwError(() => {
      const httpError = `message: ${errorMessage}, requestUrl: ${error.url}, response code: ${
        error.status
      }, body: ${JSON.stringify(error.error)}`;
      const errorObj = new Error(httpError);
      Sentry.captureException(errorObj);
      return error;
    });
  }

  show(errorMessage: any) {
    const defaultOtherAlertsEnabled =
      this.store.selectSnapshot(ProjectSettingsState.getProjectSettings)?.defaultOtherAlerts ?? true;

    if (defaultOtherAlertsEnabled) {
      this.alertHandlerService.showAlertFn('error', errorMessage);
    }
  }

  isJSONString(str: string) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  private sendToLogin() {
    console.log(`ErrorHandlerInterceptor sendToLogin: clearLocalStorageExcept`);
    clearLocalStorageExcept([
      'account',
      'transactions',
      'beneficiary',
      'notifications',
      'lastLoggedInId',
      'APP_PREFERENCES',
      'firstTime',
    ]);
    this.keycloakService.login({
      redirectUri: window.location.href,
    });
  }
}
