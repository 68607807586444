import { Pipe, PipeTransform } from '@angular/core';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { removeStyleAndScriptTags } from '@app/finxone-web-frontend/app/lib/utils/remove-style-script-tags.util';

@Pipe({ name: 'safeHtml', pure: true })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}

  transform(value: string, removeTag = true): SafeHtml {
    // Remove <style> and <script> tags from the HTML content
    const sanitizedValue = removeTag ? removeStyleAndScriptTags(value) : value;

    const cleanedValue = this.cleanIt(sanitizedValue);

    // Use bypassSecurityTrustHtml on the modified HTML
    return this.sanitized.bypassSecurityTrustHtml(cleanedValue);
  }

  private cleanIt(input: string): string {
    // Use a regex to remove broken skeletons that look like this: &lt;div class="skeleton_25"&gt;&lt;/div&gt;
    const output =
      input?.replace(/^.*&lt;div class="skeleton_(25|50|75|100)"&gt;&lt;\/div&gt;.*$/gm, '') || input;
    return output;
  }
}
