/* eslint-disable @typescript-eslint/no-explicit-any */
import { urlSearchParamsToJson } from '@app/finxone-web-frontend/app/Modules/zones/utils';
import { Currency } from '@app/finxone-web-frontend/app/lib/dtos/currencies.dto';
import { CountriesCurrenciesService } from '@app/finxone-web-frontend/app/lib/services/countries-currencies-service/countries-currencies.service';
import { PageContextState } from '@app/finxone-web-frontend/app/lib/state/page-context.state';
import { addDashesToSortCode } from '@app/finxone-web-frontend/app/lib/utils/template/string.utils';
import { flattenObject } from '@app/finxone-web-frontend/app/lib/utils/utils';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { IconCacheService } from '@finxone-platform/shared/services';
import {
  CardStatusType,
  EventGuestStateModel,
  ProfileStateModel,
  UserInfoType,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { toPath } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, combineLatest, first, map, of, switchMap, take } from 'rxjs';
import { GetAccount } from '../../actions/account.action';
import { GetBeneficiary } from '../../actions/beneficiary.action';
import { GetCards } from '../../actions/card.actions';
import { GetNextTripDetails, GetTotalLinkedTripToEmployee } from '../../actions/crud-entity.action';
import { GetManageUserDetail } from '../../actions/manage-user-detail.action';
import { GetNotification, SetSelectedNotification } from '../../actions/notification.action';
import { GetOrganisation } from '../../actions/organisation.action';
import { GetPaymentSummaryCounters } from '../../actions/payment-summary-counters.action';
import { RefreshCurrentEntity } from '../../actions/travel-mgmt-entity.actions';
import { GetProfile } from '../../actions/user-profile.action';
import { Country } from '../../dtos/countries.dto';
import { GetBeneficiaryResponse, TransactionStateType } from '../../services/account-service/account.type';
import { AccountState, AccountStateModel } from '../../state/account.state';
import { AppDataState, AppDataStateModel } from '../../state/app-data.state';
import { BeneficiaryState, BeneficiaryStateModel } from '../../state/beneficiary.state';
import { BulkPaymentListState } from '../../state/bulk-payment.state';
import { CardState, CardStateModel } from '../../state/card.state';
import { CopResponseState, CopResponseStateModel } from '../../state/cop-response.state';
import { CrudEntityState } from '../../state/crud-entity.state';
import { EventGuestState } from '../../state/event-guest.state';
import { FeeManagementState, FeeManagementStateModel } from '../../state/fee-management.state';
import { FormActionState } from '../../state/form-submision.state';
import { ManageUserDetailState } from '../../state/manage-user-detail.state';
import {
  NotificationState,
  NotificationStateModel,
  PaymentRequestSummary,
} from '../../state/notifications.state';
import { OrganisationState, OrganisationStateModel } from '../../state/organisation.state';
import { PaymentSummaryCounterState } from '../../state/payment-summary-counters.state';
import { RevenirState, RevenirStateModel } from '../../state/revenir.state';
import { TransactionState } from '../../state/transaction.state';
import { TravelMgmtEntityState } from '../../state/travel-mgmt-entity.state';
import { ProfileState } from '../../state/user-profile.state';
import { UsersListState } from '../../state/users-list.state';
import { selectedNotificationSkeleton } from '@app/finxone-web-frontend/app/lib/utils/template/skeletons/notification.skeletons';
import { currentCardTransactionDetailSkeleton } from '@app/finxone-web-frontend/app/lib/utils/template/skeletons/cards.skeletons';
import { SetCurrentCardTransactionDetail } from '@app/finxone-web-frontend/app/lib/actions/card-management.action';

export function filterIfHandlebarNotRendered(content: string) {
  const containDoubleBraces = /.*({{).*(}}).*/.test(content);
  if (containDoubleBraces) return '';
  else return content;
}

export type GenericHandleBarModel = {
  appData: AppDataStateModel;
  profile: ProfileStateModel;
  cards: CardStateModel;
  accounts: AccountStateModel;
  availableBalance: string;
  accountBalance: string;
  currency: string;
  sortCode: string;
  accountNumber: string;
  iban: string;
  ledgerNumber: string;
  bic: string;
  selectedBeneficiary: GetBeneficiaryResponse | object;
  paymentRequest: Record<string, string> | undefined;
  baseFormStateData: Record<string, string> | undefined;
  beneficiaries: BeneficiaryStateModel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $entity: any;
  beneficiaryDetails: any;
  copResponse: CopResponseStateModel;
  notification: NotificationStateModel;
  accountCreatedDate: string;
  currencyFullName: string;
  pageContext: Record<string, string>;
  intlBeneficiaryAdd: Record<string, string> | undefined;
  countryName: string;
  organisationData: OrganisationStateModel;
  customQuestionFormData: Record<string, string>;
  formSubmissionFormData: Record<string, string> | undefined;
  usersCount$: number;
  addEmployeeFormState: Record<string, string> | undefined;
  eventGuestDetails?: EventGuestStateModel;
  addUserForm?: Record<string, any>;
  tripEmployee: {
    totalLinkedTrip: number;
    trip: any[];
  };
  manageUserView: UserInfoType;
  beneficiaryDetail: GetBeneficiaryResponse | undefined;
  paymentRequestSummary: PaymentRequestSummary | undefined;
  profileBankStatementFormState: Record<string, string> | undefined;
  tripZoneFormState: Observable<any>;
  feeManagement: FeeManagementStateModel;
  feeGroupDataFromFormState: Record<string, string> | undefined;
  revenirFormState: RevenirStateModel;
  urlParamValue: Record<string, string>;
  transaction: TransactionStateType;
  currentCardIsOnXPay?: boolean;
  sepaFormState: Record<string, string>;
};

export function buildHandleBarModel(
  store: Store,
  template: string,
  countriesCurrenciesService: CountriesCurrenciesService,
  iconCacheService: IconCacheService,
  deviceService?: DeviceDetectorService,
): Observable<GenericHandleBarModel> {
  if (template) findAndRefreshRequiredData(store, template);

  const selectedBeneficiary$ = getBeneficiaryDetails(store);
  const paymentRequest$ = paymentRequestData(store);
  const appData$ = store.select(AppDataState.getAppData);
  const profile$ = store.select(ProfileState.getProfile);
  const cards$ = store.select(CardState.getCards);
  const accounts$ = store.select(AccountState.getAccounts);
  const beneficiaries$ = store.select(BeneficiaryState.getBeneficiary);
  const entity$ = store.select(TravelMgmtEntityState.getCurrentEntity);
  const addBeneficiaryForm$ = fetchFormDataById(store, FormActionTypeEnum.ADD_BENEFICIARY);
  const editBeneficiaryForm$ = fetchFormDataById(store, FormActionTypeEnum.EDIT_BENEFICIARY);
  const copResponse$ = store.select(CopResponseState.getCopResponseState);
  const notification$ = store.select(NotificationState.getNotification);
  const currencies$ = countriesCurrenciesService.getCurrencies();
  const countries$ = countriesCurrenciesService.getCountries();
  const pageContext$ = store.select(PageContextState.getPageContextState);
  const intlBeneficiaryAdd$ = fetchFormDataById(store, FormActionTypeEnum.INTL_BENEFICIARY_ADD);
  const currentFacility = store.select(CrudEntityState.getCurrentFacility);
  const currentEvent = store.select(CrudEntityState.getCurrentEvent);
  const tripEmployee = store.select(CrudEntityState.getTripEmployee);
  const kycFormData = fetchFormDataById(store, FormActionTypeEnum.KYC_DETAILS);
  const profileFormData = fetchFormDataById(store, FormActionTypeEnum.EDIT_PROFILE_DETAILS);
  const organisationData$ = store.select(OrganisationState.getOrganisation);
  const customQuestionFormData = fetchFormDataById(store, FormActionTypeEnum.CUSTOM_QUESTION);
  const formSubmissionFormData$ = formSubmissionFormData(store);
  const addEmployeeFormState = formActionStateValueWithID(store, FormActionTypeEnum.ADD_EMPLOYEE);
  const feeGroupDataFromFormState = formActionStateValueWithID(store, FormActionTypeEnum.ADD_FEE_GROUP);
  const eventGuestDetails = store.select(EventGuestState.getEventGuestDetails);
  const addUserForm = fetchFormDataById(store, FormActionTypeEnum.MANAGE_USER);
  const manageUserView = store.select(ManageUserDetailState.getUser);

  const usersCount$ = store.select(UsersListState.getUsersCount);
  const selectedBeneficiaryFromBeneficiaryZone$ = getSelectedBeneficiaryDetail(store);
  const paymentRequestSummary$ = getPaymentRequestSummary(store);
  const baseFormStateData$ = baseFormStateDataData(store);
  const bulkPaymentSummary = store.select(BulkPaymentListState.getBulkPaymentSummary);
  const profileBankStatementFormState = formActionStateValueWithID(
    store,
    FormActionTypeEnum.PROFILE_BANK_STATEMENT,
  );
  const paymentSummaryCounters = store.select(PaymentSummaryCounterState.getPaymentSummaryCounters);
  const tripZoneFormState = formActionStateValueWithID(store, FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE);
  const revenirFormState = store.select(RevenirState.getRevenirStateDetails);
  const feeManagement$ = store.select(FeeManagementState.getFeeManagement);
  const selectedClientDetails$ = getSelectedClientDetails(store);
  const urlParamValue = getPageUrlParam();
  const transaction = store.select(TransactionState.getTransactions);
  const sepaFormState = formActionStateValueWithID(store, FormActionTypeEnum.SEPA_PAYMENT);

  return combineLatest([
    // if a fetch was triggeed then this will emit to trigger rerender once fetched
    iconCacheService.iconLoaded$,
    appData$,
    profile$,
    cards$,
    accounts$,
    selectedBeneficiary$,
    paymentRequest$,
    beneficiaries$,
    addBeneficiaryForm$,
    editBeneficiaryForm$,
    copResponse$,
    entity$,
    notification$,
    currencies$,
    countries$,
    pageContext$,
    intlBeneficiaryAdd$,
    currentFacility,
    currentEvent,
    organisationData$,
    kycFormData,
    profileFormData,
    customQuestionFormData,
    formSubmissionFormData$,
    usersCount$,
    addEmployeeFormState,
    eventGuestDetails,
    addUserForm,
    tripEmployee,
    manageUserView,
    selectedBeneficiaryFromBeneficiaryZone$,
    paymentRequestSummary$,
    profileBankStatementFormState,
    baseFormStateData$,
    bulkPaymentSummary,
    tripZoneFormState,
    feeManagement$,
    selectedClientDetails$,
    feeGroupDataFromFormState,
    revenirFormState,
    urlParamValue,
    transaction,
    paymentSummaryCounters,
    sepaFormState,
  ]).pipe(
    map(
      ([
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        iconLoaded,
        appData,
        profile,
        cards,
        accounts,
        selectedBeneficiary,
        paymentRequest,
        beneficiaries,
        addBeneficiaryForm,
        editBeneficiaryForm,
        copResponse,
        entity,
        notification,
        currencies,
        countries,
        pageContext,
        intlBeneficiaryAdd,
        currentFacility,
        currentEvent,
        organisationData,
        kycFormData,
        profileFormData,
        customQuestionFormData,
        formSubmissionFormData,
        usersCount$,
        addEmployeeFormState,
        eventGuestDetails,
        addUserForm,
        tripEmployee,
        manageUserView,
        selectedBeneficiaryFromBeneficiaryZone$,
        paymentRequestSummary$,
        profileBankStatementFormState,
        baseFormStateData$,
        bulkPaymentSummary,
        tripZoneFormState,
        feeManagement$,
        selectedClientDetails$,
        feeGroupDataFromFormState,
        revenirFormState,
        urlParamValue,
        transaction,
        paymentSummaryCounters,
        sepaFormState,
      ]) => {
        const currentCardIsOnXPay = function (currentCardMaskedPan: string) {
          const tokenList = localStorage.getItem('tokenList');
          if (!tokenList) return;

          let parsedTokenList: any[] = [];

          if (tokenList) {
            try {
              parsedTokenList = JSON.parse(tokenList) || [];
            } catch (error) {
              console.error('Error', error);
            }
          }

          /* Check in the token list if any fpanLastFour matches the last four digits of the current card ID. */
          return parsedTokenList.some((token) => token.fpanLastFour === currentCardMaskedPan.slice(-4));
        };

        const selectedAccountDetail = accounts.accounts?.find(
          (account) => account.accountId === accounts.currentAccountId,
        );
        const appPreferences = JSON.parse(localStorage.getItem('APP_PREFERENCES') as string) ?? {};
        const templateModel = {
          appData: appData,
          profile: {
            ...profile,
            firstNameInitial: profile.firstName ? profile.firstName[0] : '',
            middleNameInitial: profile.middleName ? profile.middleName[0] : '',
            lastNameInitial: profile.lastName ? profile.lastName[0] : '',
          },
          cards: cards,
          cardFreezeState: cards?.currentCardDetail?.status === CardStatusType.FROZEN ? 'Unfreeze' : 'Freeze',
          accounts: accounts,
          alias: selectedAccountDetail?.alias ?? '',
          availableBalance: selectedAccountDetail?.availableBalance ?? '',
          accountBalance: selectedAccountDetail?.balance ?? '',
          indicativeBalance: selectedAccountDetail?.indicativeBalance ?? '',
          ledgerBalance: selectedAccountDetail?.ledgerBalance ?? '',
          ledgerBalanceIncludingPendingExchange:
            selectedAccountDetail?.ledgerBalanceIncludingPendingExchange ?? '',
          pendingExchangeNetTotal: selectedAccountDetail?.scheduledExchanges?.netTotal ?? '',
          pendingExchangeCount: selectedAccountDetail?.scheduledExchanges?.totalBooked ?? '',
          pendingTransactionNetTotal: selectedAccountDetail?.scheduledPayments?.netTotal ?? '',
          pendingTransactionCount: selectedAccountDetail?.scheduledPayments?.totalBooked ?? '',
          currency: selectedAccountDetail?.currency ?? '', // TODO: needs deprecating as its badly named - accountCurrency should be used.
          accountCurrency: selectedAccountDetail?.currency ?? '',
          country: selectedAccountDetail?.country ?? '', // TODO: needs deprecating as its badly named - accountCountry should be used.
          accountCountry: selectedAccountDetail?.country ?? '',
          sortCode: addDashesToSortCode(selectedAccountDetail?.accountIdentifier?.[0]?.sortCode ?? ''),
          accountNumber: selectedAccountDetail?.accountIdentifier?.[0]?.accountNumber ?? '',
          iban: selectedAccountDetail?.accountIdentifier[0]?.iban ?? '',
          bic: selectedAccountDetail?.accountIdentifier[0]?.bic ?? '',
          achAccountNumber:
            (selectedAccountDetail?.accountIdentifier[0]?.providerExtraInfo as any)?.ach?.accountNumber ?? '',
          achNumber:
            (selectedAccountDetail?.accountIdentifier[0]?.providerExtraInfo as any)?.ach?.routingCodes?.aba ??
            '',
          abaAccountNumber:
            (selectedAccountDetail?.accountIdentifier[0]?.providerExtraInfo as any)?.wire?.accountNumber ??
            '',
          abaNumber:
            (selectedAccountDetail?.accountIdentifier[0]?.providerExtraInfo as any)?.wire?.routingCodes
              ?.aba ?? '',
          accountProviderExtraInfo: selectedAccountDetail?.accountIdentifier[0]?.providerExtraInfo ?? {},
          ledgerNumber: selectedAccountDetail?.accountIdentifier[0]?.ledgerNumber ?? '',
          bsbCode: selectedAccountDetail?.accountIdentifier[0]?.bsbCode ?? '',
          ifsc: selectedAccountDetail?.accountIdentifier[0]?.ifsc ?? '',
          clabe: selectedAccountDetail?.accountIdentifier[0]?.clabe ?? '',
          bankCode: selectedAccountDetail?.accountIdentifier[0]?.bankCode ?? '',
          branchCode: selectedAccountDetail?.accountIdentifier[0]?.branchCode ?? '',
          rtnCanada: selectedAccountDetail?.accountIdentifier[0]?.rtnCanada ?? '',
          accountCreatedDate: selectedAccountDetail?.accountCreatedDate ?? '',
          customerName: selectedAccountDetail?.customerName ?? '',
          selectedAccountCustomerName: selectedAccountDetail?.customerName ?? '',
          selectedAccountName: selectedAccountDetail?.name ?? '',
          currencyFullName:
            currencies?.find(
              (currency: Currency) => currency.currency_code === selectedAccountDetail?.currency,
            )?.currency_name ?? 'Unknown Currency',
          selectedBeneficiary,
          paymentRequest,
          baseFormStateData: baseFormStateData$,
          beneficiaries: beneficiaries,
          beneficiaryDetails: flattenObject(addBeneficiaryForm ?? editBeneficiaryForm),
          copResponse: copResponse,
          $entity: entity,
          notification: notification,
          pageContext: pageContext.pageContextData,
          formSubmissionFormData,
          userPendingKycForm: kycFormData,
          editUserProfileForm: profileFormData,
          intlBeneficiaryAdd,
          currentFacility,
          currentEvent,
          countryName:
            countries?.find((country: Country) => country.code === selectedAccountDetail?.country)?.name ??
            'Unknown Country',
          organisationData: organisationData,
          customQuestionFormData,
          usersCount$,
          addEmployeeFormState,
          eventGuestDetails: eventGuestDetails,
          addUserForm: addUserForm,
          tripEmployee,
          manageUserView: manageUserView,
          beneficiaryDetail: selectedBeneficiaryFromBeneficiaryZone$,
          paymentRequestSummary: paymentRequestSummary$,
          profileBankStatementFormState,
          bulkPaymentSummary: bulkPaymentSummary,
          tripZoneFormState,
          feeManagement: feeManagement$,
          selectedClientDetails: {
            ...selectedClientDetails$,
            accountsLinked: selectedClientDetails$?.accountList?.length,
            address1: selectedClientDetails$?.attributes.address.address1,
            address2: selectedClientDetails$?.attributes.address.address2,
            address3: selectedClientDetails$?.attributes.address.address3,
            address4: selectedClientDetails$?.attributes.address.address4,
            postalCode: selectedClientDetails$?.attributes.address.postalCode,
            city: selectedClientDetails$?.attributes.address.city,
            country: selectedClientDetails$?.attributes.address.country,
            state: selectedClientDetails$?.attributes.address.state,
          },
          feeGroupDataFromFormState: feeGroupDataFromFormState,
          revenirFormState,
          urlParamValue,
          transaction,
          paymentSummaryCounters,
          deviceInfo: deviceService?.getDeviceInfo(),
          isMobileApp: localStorage.getItem('APP_PREFERENCES') !== null,
          APP_PREFERENCES: appPreferences,
          currentCardIsOnXPay: currentCardIsOnXPay(cards.currentCardDetail?.maskedPan ?? '****'),
          sepaFormState,
        };
        return templateModel;
      },
    ),
  );
}

const formSubmissionFormData = (store: Store) => {
  const formData$ = store.select(FormActionState.getFormActionState).pipe(
    map((formActionStateData) => {
      return formActionStateData.response?.formData;
    }),
  );
  return formData$;
};

const paymentRequestData = (store: Store): Observable<any> => {
  return store
    .select(FormActionState.getFormActionStateWithId(FormActionTypeEnum.INTL_TRANSFER_RESPONSE))
    .pipe(
      switchMap((intlFormActionStateData) => {
        if (intlFormActionStateData?.formData) {
          return of(intlFormActionStateData.formData);
        } else {
          // If the intl select is empty, use the general form Action state for domestic.
          return store.select(FormActionState.getFormActionState).pipe(
            map((formActionStateData) => {
              return formActionStateData.response?.formData;
            }),
          );
        }
      }),
    );
};

const baseFormStateDataData = (store: Store): Observable<any> => {
  return store.select(FormActionState.getFormActionState).pipe(
    map((formActionStateData) => {
      return formActionStateData.response?.formData;
    }),
  );
};

const formActionStateValueWithID = (store: Store, formId: FormActionTypeEnum) => {
  return store.select(FormActionState.getFormActionStateWithId(formId)).pipe(
    map((formActionStateData) => {
      return formActionStateData?.formData;
    }),
  );
};

const getBeneficiaryDetails = (store: Store): Observable<GetBeneficiaryResponse | object> => {
  const selectedBeneficiary$ = combineLatest([
    formSubmissionFormData(store),
    store.select(BeneficiaryState.getBeneficiary),
  ]).pipe(
    map(([formActionStateData, beneficiaryList]) => {
      const beneficiaryId = formActionStateData?.['bid'];
      return beneficiaryList?.items?.find((item) => item.id === beneficiaryId) ?? {};
    }),
  );
  return selectedBeneficiary$;
};

export function findAndRefreshRequiredData(store: Store, template: string): void {
  const profilePresent = /profile./.exec(template);
  const cardsPresent = /cards./.exec(template);
  const accountsPresent =
    /accounts.|{{.*(availableBalance|accountBalance|currency|sortCode|accountNumber|iban|ledgerNumber|bic).*}}/.exec(
      template,
    );
  const beneficiariesPresent = /beneficiaries./.exec(template);
  const entity = /\$entity.*\.[A-Za-z]*/.exec(template);
  const selectedBeneficiaryPresent = /selectedBeneficiary./.exec(template);
  const notificationPresent = /notification.*\.[A-Za-z]*/.exec(template);
  const organisationDataPresent = /organisationData.*\.[A-Za-z]*/.exec(template);
  const manageUserViewPresent = /manageUserView./.exec(template);

  const isTotalLinkedTripPresent = /tripEmployee.totalLinkedTrip./.exec(template);

  const isNextTripPresent = /tripEmployee.nextTripAt./.exec(template);
  const paymentSummaryCountersPresent = /paymentSummaryCounters/.exec(template);
  if (isTotalLinkedTripPresent) {
    store.dispatch(new GetTotalLinkedTripToEmployee());
  }

  if (isNextTripPresent) {
    store.dispatch(new GetNextTripDetails());
  }

  if (notificationPresent) {
    store.dispatch(new SetSelectedNotification(selectedNotificationSkeleton)); // Set skeleton template first
    store.dispatch(new GetNotification());
  }

  if (manageUserViewPresent) {
    const viewUserProfile$ = store.select(ManageUserDetailState.getUser);
    viewUserProfile$
      .pipe(
        first(),
        map((viewUserData) => {
          if (!viewUserData?.id)
            store.dispatch(new GetManageUserDetail(viewUserData.id as string)).subscribe();
        }),
      )
      .subscribe();
  }
  if (profilePresent) {
    const profile$ = store.select(ProfileState.getProfile);
    profile$
      .pipe(
        first(),
        map((profileData) => {
          if (!profileData?.firstName) store.dispatch(new GetProfile()).subscribe();
        }),
      )
      .subscribe();
  }

  if (accountsPresent) {
    store.dispatch(new GetAccount(1, 50));
  }

  if (cardsPresent) {
    store.dispatch(new SetCurrentCardTransactionDetail(currentCardTransactionDetailSkeleton));
    store.dispatch(new GetCards());
  }

  if (beneficiariesPresent) {
    store.dispatch(new GetBeneficiary());
  }

  if (selectedBeneficiaryPresent) {
    const formData = store.selectSnapshot(FormActionState.getFormActionState)?.response?.formData;
    if (formData) store.dispatch(new GetBeneficiary(1, 25, formData.name, true, formData.currency));
  }

  if (entity) {
    const path = toPath(entity[0]);
    const entityName = path[1];
    const type = path[2].includes('count') ? path[2] : '';
    store.dispatch(new RefreshCurrentEntity(entityName, type));
  }

  if (organisationDataPresent) {
    store.dispatch(new GetProfile());
    const profile$ = store.select(ProfileState.getProfile);
    profile$
      .pipe(
        take(1),
        map((profileData) => {
          if (profileData.activeOrganisationId) {
            store.dispatch(new GetOrganisation(profileData?.activeOrganisationId ?? ''));
          }
        }),
      )
      .subscribe();
  }

  if (paymentSummaryCountersPresent) {
    //find all properties after the base 'paymentSummaryCounters' handlebar
    const regex = /{{\s*paymentSummaryCounters\.(\w+)\s*}}/g;
    const properties = [...template.matchAll(regex)].map((match) => match[1]);
    store.dispatch(new GetPaymentSummaryCounters(properties));
  }
}

function fetchFormDataById(store: Store, formActionId: FormActionTypeEnum) {
  const formData$ = store.select(FormActionState.getFormActionState).pipe(
    map((formActionStateData) => {
      return formActionStateData[formActionId]?.formData;
    }),
  );
  return formData$;
}

function getSelectedBeneficiaryDetail(store: Store) {
  const formData = store.selectSnapshot(FormActionState.getFormActionState)?.response?.formData;
  const beneficiary$ = store.select(BeneficiaryState.setSelectedBeneficiary).pipe(
    map((beneficiaryData) => {
      return beneficiaryData.items?.find((v) => v.id === formData.bid);
    }),
  );
  return beneficiary$;
}

function getPaymentRequestSummary(store: Store) {
  const paymentRequestSummary$ = store.select(NotificationState.getNotification).pipe(
    map((notificationState) => {
      return notificationState?.paymentRequestSummary;
    }),
  );
  return paymentRequestSummary$;
}

function getSelectedClientDetails(store: Store) {
  const selectedClientDetails$ = store.select(FeeManagementState.getFeeManagement).pipe(
    map((feeManagementState) => {
      return feeManagementState?.selectedClientDetails;
    }),
  );
  return selectedClientDetails$;
}

/**
 * @description: Example value: eq urlParamValue.flow 'bulk-payment'
 * urlParamValue is the key of url params
 * flow can be dynamic, that is query string key
 * 'bulk-payment' is a value of that query string
 */

const getPageUrlParam = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return of(urlSearchParamsToJson(urlParams));
};
