import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceLocalData } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FILE_ENDPOINTS, IS_EXTERNAL_KEYCLOAK } from './http-constants';
import { KeycloakWrapperService } from './services/keycloak-wrapper-service/keycloak-wrapper.service';
import { ProfileState } from './state/user-profile.state';

export const BYPASS_AUTH_INTERCEPTOR = new HttpContextToken<boolean>(() => false);

@Injectable()
export class httpInterceptor implements HttpInterceptor {
  constructor(private store: Store, private keycloakService: KeycloakWrapperService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const keycloakToken = this.keycloakService.getKeycloakInstance()?.token;
    const localStorageToken = localStorage.getItem('token');

    let updatedToken: string | undefined = keycloakToken
      ? `Bearer ${keycloakToken}`
      : localStorageToken
      ? `Bearer ${localStorageToken}`
      : undefined;

    if (updatedToken === undefined) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any).accessToken) {
        console.log(`updatedToken is undefined and fallback to window token and proceed`);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updatedToken = `Bearer ${(window as any).accessToken}`;
      } else {
        console.warn(`updatedToken is undefined and no fallback window token so proceeding`);
      }
    }

    if (req.context.get(BYPASS_AUTH_INTERCEPTOR)) {
      updatedToken = undefined;
    }

    if (req.context.get(IS_EXTERNAL_KEYCLOAK)) {
      // For external keycloak requests, only include essential headers otherwise may be blocked by cors policies
      const simpleReq: HttpRequest<any> = req.clone({
        setHeaders: updatedToken
          ? {
              Authorization: updatedToken,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            }
          : {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
      });
      return next.handle(simpleReq);
    }

    const deviceInfo = localStorage.getItem('deviceInfo');
    let deviceData: DeviceLocalData;
    try {
      deviceData = JSON.parse(deviceInfo ?? '{}');
    } catch (error) {
      deviceData = {} as DeviceLocalData;
    }
    const deviceManufacturer = deviceData?.manufacturer ?? '';
    const deviceOS = deviceData?.os ?? '';
    const deviceModel = deviceData?.deviceModel ?? '';
    const deviceId = deviceData?.deviceId ?? '';
    const profileData = this.store.selectSnapshot(ProfileState.getProfile);
    const organisation = profileData?.activeOrganisationId ?? '';
    const role = profileData?.activeRole ?? '';
    let authHeaderValue = undefined;

    if (
      new RegExp(window.location.hostname).exec(req.url) ||
      /finxone.com/.exec(req.url) ||
      /localhost/.exec(req.url)
    ) {
      authHeaderValue = updatedToken
        ? {
            Authorization: updatedToken,
          }
        : {};
    } else {
      authHeaderValue = {};
    }

    const body = req.body;
    let otpValue: string | undefined;

    if (body && 'otp' in body) {
      otpValue = body.otp; // Extract the OTP from the body
      delete body.otp; // Remove OTP from the body
    }

    const commonHeaders = {
      ...authHeaderValue,
      organisation: organisation,
      role: role,
      'X-Finxone-Org-Id': organisation,
      'X-Finxone-Role': role,
      'X-Finxone-User-Agent': window.navigator.userAgent,
      'X-Finxone-Device-Id': deviceId,
      'X-Finxone-Device-Manufacture': deviceManufacturer,
      'X-Finxone-Device-Os': deviceOS,
      'X-Finxone-Device-Model': deviceModel,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET/POST/PUT/DELETE',
    };
    const isFileRequest: boolean = FILE_ENDPOINTS.some((endpoint) => req.url.includes(endpoint));

    const headers = {
      ...commonHeaders,
      ...(isFileRequest
        ? {
            Accept: '*/*',
          }
        : {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            ...(otpValue ? { 'X-Finxone-otp': otpValue } : {}),
          }),
    };

    return next.handle(req.clone({ setHeaders: headers }));
  }
}
